import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import * as _ from 'lodash';

import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';


@Injectable()
export class RegUserGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

    return this.authService.userRoles
      .take(1)
      .map(roles => {
        console.log('in the canActivate of the RegUserGuard and the user object is: ', roles);
        if (roles) {
          return roles.sysadmin === true || roles.reguser === true;
        } else {
          return false;
        }
      })
      .do(authorized => {
        if (!authorized) {
          console.log('route prevented!');
          this.router.navigate(['/']);
        }
      });

  }
}
