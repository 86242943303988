// Put shared types for dropdown menus and UI labels etc. in here to be used through the whole app
// Could be used for Localization or Label customization as needed
import { Injectable } from '@angular/core';


@Injectable()
export class GlobalUtilitiesService {

    public alerts: any = [];

    public provinces = [
        { value: '', display: 'Select a Province ...' },
        { value: 'AB', display: 'Alberta' },
        { value: 'BC', display: 'British Columbia' },
        { value: 'MB', display: 'Manitoba' },
        { value: 'NB', display: 'New Brunswick' },
        { value: 'NL', display: 'Newfoundland and Labrador' },
        { value: 'NS', display: 'Nova Scotia' },
        { value: 'ON', display: 'Ontario' },
        { value: 'PE', display: 'Prince Edward Island' },
        { value: 'QC', display: 'Quebec' },
        { value: 'SK', display: 'Saskatchewan' },
        { value: 'NT', display: 'Northwest Territories' },
        { value: 'NU', display: 'Nunavut' },
        { value: 'YT', display: 'Yukon' }
    ];

    public states = [
        { value: '', display: 'Select a State ...' },
        { value: 'AK', display: 'Alaska' },
        { value: 'AL', display: 'Alabama' },
        { value: 'AK', display: 'Alaska' },
        { value: 'AL', display: 'Alabama' },
        { value: 'AR', display: 'Arkansas' },
        { value: 'AS', display: 'American Samoa' },
        { value: 'AZ', display: 'Arizona' },
        { value: 'CA', display: 'California' },
        { value: 'CO', display: 'Colorado' },
        { value: 'CT', display: 'Connecticut' },
        { value: 'DC', display: 'District of Columbia' },
        { value: 'DE', display: 'Delaware' },
        { value: 'FL', display: 'Florida' },
        { value: 'FM', display: 'Federated States of Micronesia' },
        { value: 'GA', display: 'Georgia' },
        { value: 'GU', display: 'Guam' },
        { value: 'HI', display: 'Hawaii' },
        { value: 'IA', display: 'Iowa' },
        { value: 'ID', display: 'Idaho' },
        { value: 'IL', display: 'Illinois' },
        { value: 'IN', display: 'Indiana' },
        { value: 'KS', display: 'Kansas' },
        { value: 'KY', display: 'Kentucky' },
        { value: 'LA', display: 'Louisiana' },
        { value: 'MA', display: 'Massachusetts' },
        { value: 'MD', display: 'Maryland' },
        { value: 'ME', display: 'Maine' },
        { value: 'MH', display: 'Marshall Islands' },
        { value: 'MI', display: 'Michigan' },
        { value: 'MN', display: 'Minnesota' },
        { value: 'MO', display: 'Missouri' },
        { value: 'MP', display: 'Northern Mariana Islands' },
        { value: 'MS', display: 'Mississippi' },
        { value: 'MT', display: 'Montana' },
        { value: 'NC', display: 'North Carolina' },
        { value: 'ND', display: 'North Dakota' },
        { value: 'NE', display: 'Nebraska' },
        { value: 'NH', display: 'New Hampshire' },
        { value: 'NJ', display: 'New Jersey' },
        { value: 'NM', display: 'New Mexico' },
        { value: 'NV', display: 'Nevada' },
        { value: 'NY', display: 'New York' },
        { value: 'OH', display: 'Ohio' },
        { value: 'OK', display: 'Oklahoma' },
        { value: 'OR', display: 'Oregon' },
        { value: 'PA', display: 'Pennsylvania' },
        { value: 'PR', display: 'Puerto Rico' },
        { value: 'PW', display: 'Palau' },
        { value: 'RI', display: 'Rhode Island' },
        { value: 'SC', display: 'South Carolina' },
        { value: 'SD', display: 'South Dakota' },
        { value: 'TN', display: 'Tennessee' },
        { value: 'TX', display: 'Texas' },
        { value: 'UM', display: 'U.S. Minor Outlying Islands' },
        { value: 'UT', display: 'Utah' },
        { value: 'VA', display: 'Virginia' },
        { value: 'VI', display: 'Virgin Islands of the U.S.' },
        { value: 'VT', display: 'Vermont' },
        { value: 'WA', display: 'Washington' },
        { value: 'WI', display: 'Wisconsin' },
        { value: 'WV', display: 'West Virginia' },
        { value: 'WY', display: 'Wyoming' }
    ];

    public countries = [
        { value: 'USA', display: 'United States' },
        { value: 'Canada', display: 'Canada' }
    ];


    public statusKey = [
        { value: '0', display: 'Not Created' },
        { value: '1', display: 'Created - no price' },
        { value: '2', display: 'Priced - not paid' },
        { value: '3', display: 'Paid - no video' },
        { value: '4', display: 'Video submitted' },
        { value: '5', display: 'Assigned to evaluator' },
        { value: '6', display: 'Evaluation started' },
        { value: '7', display: 'Tagging completed' },
        { value: '8', display: 'Evaluation completed' }
    ];


    constructor() { }

    statusValueForKey(index: number): string {
        return this.statusKey[index].display;
    }

    hexCodeForColor(theColor: string): string {
        const defaultColor = '#888888';
        if (theColor.toLowerCase() === 'red') {
            return '#9b1d20';
        }
        if (theColor.toLowerCase() === 'blue') {
            return '#336699';
        }
        if (theColor.toLowerCase() === 'gold') {
            return '#ffc857';
        }
        if (theColor.toLowerCase() === 'green') {
            return '#136f63';
        }
        if (theColor.toLowerCase() === 'purple') {
            return '#5d2e8c';
        }

        // return the default
        return defaultColor;
    }

}
