import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
// import { AngularFireModule } from 'angularfire2';
import * as firebase from 'firebase';
// import { Observable } from 'rxjs';
// import { storage } from 'firebase/app';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class StorageService {


  constructor(private afs: AngularFirestore, private http: HttpClient) { }


  generateFrameGrabs(forEvaluationId: string, forVideoURL: string, atIndexPoints: number[]) {

    const tempURL = 'https://us-central1-practice-evaluation-3.cloudfunctions.net/getFramesFromVideoL';

    const httpBody: any = {};
    // httpBody.data = theLocation
    httpBody.videoURL = forVideoURL;
    httpBody.timeIntervals = atIndexPoints;
    httpBody.evalUid = forEvaluationId;

    return new Promise((resolve, reject) => {
      console.log('In Storgae Service getting frame grabs ... and the httpBody that will be sent is: ', httpBody);

      this.http
        .post(tempURL, httpBody)
        .subscribe(
          data => {
            console.log('Storgae Service getting frame grabs after the HTTP call');
            const resp: any = data;
            console.log('Storgae Service getting frame grabs after the HTTP call and the tempResponse is: ', resp);

            if (resp.result === 'success') {
              resolve({
                result: 'success',
                details: 'Frames Grabbed successfully'
              });
            } else {
              resolve({
                result: 'failure',
                details: 'Frame grab has failed'
              });
            }

          },
          error => {
            console.log('In the error portion after the call to the backend to call for the frame grabs and the error is: ', error);
            reject(error);
          }
        );
    });

  }



  deleteFileInStorage(withFileNamePath: string) {
    const tempURL = 'https://us-central1-practice-evaluation-3.cloudfunctions.net/deleteStorageObject';

    const httpBody: any = {};
    // httpBody.data = theLocation
    httpBody.theFileNamePath = withFileNamePath;

    return new Promise((resolve, reject) => {
      console.log('In Storgae Service deleting single object ... and the httpBody that will be sent is: ', httpBody);

      this.http
        .post(tempURL, httpBody)
        .subscribe(
          data => {
            const resp: any = data;
            console.log('Storgae Service deleting single object after the HTTP call and the tempResponse is: ', resp);

            if (resp.result === 'success') {
              resolve({
                result: 'success',
                details: 'Single Object deleted successfully'
              });
            } else {
              resolve({
                result: 'failure',
                details: 'Single Object delete has failed'
              });
            }

          },
          error => {
            console.log('In the error portion after the call to the backend delete an object in storage and the error is: ', error);
            reject(error);
          }
        );
    });


  }


  getVideoMetaData(forVideoURL: string) {

    const tempURL = 'https://us-central1-practice-evaluation-3.cloudfunctions.net/getVideoStats';

    const httpBody: any = {};
    // httpBody.data = theLocation
    httpBody.videoURL = forVideoURL;

    return new Promise((resolve, reject) => {
      console.log('In Storgae Service getting video stats ... and the httpBody that will be sent is: ', httpBody);

      this.http
        .post(tempURL, httpBody)
        .subscribe(
          data => {
            console.log('Storgae Service getting video stats after the HTTP call');
            const resp: any = data;
            console.log('Storgae Service getting video stats after the HTTP call and the tempResponse is: ', resp);

            if (resp.result === 'success') {
              resolve({
                result: 'success',
                details: 'Video stats returned',
                metadata: resp.metadata
              });
            } else {
              resolve({
                result: 'failure',
                details: 'Video stats has failed'
              });
            }

          },
          error => {
            console.log('In the error portion after the call to the backend to call for the Video stats and the error is: ', error);
            reject(error);
          }
        );
    });

  }


  // deleteItemUpload(upload: ItemUpload) {
  //   this.deleteFileData(upload.uid)
  //     .then(() => {
  //       this.deleteFileStorage(upload);
  //     })
  //     .catch((error) => console.log(error));
  // }

  // // Executes the file uploading to firebase https://firebase.google.com/docs/storage/web/upload-files
  // pushItemUpload(upload: ItemUpload): Promise<any> {

  //   const newUid = this.afs.createId();
  //   upload.uid = newUid;

  //   const storageRef = firebase.storage().ref();
  //   // const uploadTask = storageRef.child(`${this.basePath}/${upload.file.name}`).put(upload.file);
  //   // const uploadTask = storageRef.child(`${this.basePath}/${upload.uid}`).put(upload.file);

  //   const locationString: string = this.basePath + '/' + upload.owningUid + '/' + upload.uid;
  //   const uploadTask = storageRef.child(locationString).put(upload.file);

  //   return new Promise((resolve, reject) => {

  //     uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
  //       (snapshot: firebase.storage.UploadTaskSnapshot) => {
  //         // upload in progress
  //         const snap = snapshot;
  //         console.log('uploadTask just popped back a snapshot which is: ', snap);

  //         upload.bytesTransferred = snap.bytesTransferred;
  //         upload.size = snap.totalBytes;
  //         upload.progress = (snap.bytesTransferred / snap.totalBytes) * 100;
  //       },
  //       (error) => {
  //         // upload failed
  //         console.log('The upload failed: ');
  //         console.log(error);
  //       },
  //       () => {
  //         console.log('upload may be finished now ... the snapshot is: ');
  //         console.log(uploadTask.snapshot);

  //         // need an extra call back to get the download URL (This changed in a firebase update.)
  //         // upload success

  //         uploadTask.snapshot.ref.getDownloadURL()
  //         .then(downloadURL => {
  //           console.log('The Download URL for the uploaded file is: ', downloadURL);
  //           upload.url = downloadURL;
  //           upload.name = uploadTask.snapshot.metadata.name;
  //           upload.size = uploadTask.snapshot.metadata.size;
  //           upload.fullPath = uploadTask.snapshot.metadata.fullPath;

  //           this.saveFileData(upload);
  //           resolve(upload); // should this be a promise.
  //         })
  //         .catch(getDownloadError => {
  //           console.error('No download URL!');
  //           console.log('There was an error getting the download URL and it was: ');
  //           console.log(getDownloadError);
  //           reject('An error occurred in the file upload - there was an error');
  //         });
  //       },
  //     );
  //   });
  // }

  // // Writes the file details to Firestore
  // private saveFileData(upload: ItemUpload) {

  //   return this.uploadsCollection.doc(upload.uid).set({ ...upload.stripTemporaryFields() });
  // }

  // // Deletes the file details in Firestore
  // private deleteFileData(key: string) {
  //   return this.uploadsCollection.doc(key).delete();
  // }

  // // Firebase files must have unique names in their respective storage dir
  // // So the UID serves as a unique key
  // private deleteFileStorage(upload: ItemUpload) {
  //   const storageRef = firebase.storage().ref();
  //   const locationString: string = this.basePath + '/' + upload.owningUid + '/' + upload.uid; // could use full path here.
  //   storageRef.child(locationString).delete();
  // }


  generatePDFOnServer(theDocId: string): Promise<any> {


    const tempURL = 'https://us-central1-practice-evaluation-3.cloudfunctions.net/generatePDFReport';

    const httpBody: any = {};
    httpBody.evalUid = theDocId;

    return new Promise((resolve, reject) => {
      console.log('In Evaluation Service generating the PDF ... and the httpBody that will be sent is: ', httpBody);

      this.http
        .post(tempURL, httpBody)
        .subscribe(
          data => {
            console.log('Evaluation Service generating the PDF after the HTTP call');
            const resp: any = data;
            console.log('Evaluation Service generating the PDF after the HTTP call and the tempResponse is: ', resp);

            if (resp.result === 'success') {
              resolve({
                result: 'success',
                details: 'Generated PDF Successfully - URL Attached',
                linkURL: resp.linkURL
              });
            } else {
              resolve({
                result: 'failure',
                details: 'Generate PDF Failed'
              });
            }

          },
          error => {
            console.log('In the error portion after the call to the backend to call for generating the PDF and the error is: ', error);
            reject({
              result: 'failure',
              details: 'Generate PDF Failed',
              returnedError: error
            });
          }
        );
    });
  }








}
