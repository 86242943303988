
import { Component, OnInit, ViewChild, AfterViewInit, NgZone, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { BlobUploadService } from '../utils/file-upload/blob-upload.service';
declare const navigator: any;
declare const MediaRecorder: any;


@Component({
  selector: 'app-voice-recording',
  templateUrl: './voice-recording.component.html',
  styleUrls: ['./voice-recording.component.scss']
})
export class VoiceRecordingComponent implements OnInit, AfterViewInit {

  @Input() public theTagUid: string;

  @Output() voiceNoteUrlEvent = new EventEmitter<{}>();

  // @ViewChild('ThisRecording') thePlayer = new Audio();
  // @ViewChild('ThisRecording') thePlayer: ElementRef;
  // @ViewChild('OldRecording') oldPlayer: ElementRef;
  // public thePlayer;
  public isRecording = false;
  public isUploading = false;
  public isPlaying = false;
  private chunks: any = [];

  private mediaRecorder;

  public myAudio;
  public theDevices = [];

  private onSuccess;

  constructor(
    private zone: NgZone,
    private blobUpService: BlobUploadService
  ) {

  }

  ngOnInit() {

    navigator.getUserMedia = (navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia);

    navigator.mediaDevices.enumerateDevices().then((devices) => {
      console.log('The raw device list is: ', devices);
      this.theDevices = devices.filter((d) => d.kind === 'audioinput');
      console.log('the Devices are: ', this.theDevices);

      // SWITCH TO NEW WAY WITH NAVIGATOR.MEDIADEVICES.GETUSERMEDIA THAT RETURNS A PROMISE.
      // https://stackoverflow.com/questions/47059324/mediarecorder-record-from-multiple-microphones

      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          console.log('is this where I come after the stream ?');
          this.doCoolStuffWithTheStream(stream);
        })
        .catch(error => {
          console.log('ERROR occurred in the stream ?');
        });
    });

    // this.oldPlayer.nativeElement.src = 'https://firebasestorage.googleapis.com/v0/b/practice-evaluation-3.appspot.com/o/VoiceNotes%2F903840384038409238029348230948%2Fvoicenote?alt=media&token=ace9c112-f728-402e-bb6a-9ddfda02cb6f';
    // this.oldPlayer.nativeElement.load();


  }

  ngAfterViewInit() {
    console.log('the Devices3 are: ', this.theDevices);
  }

  async doCoolStuffWithTheStream(stream) {
    this.mediaRecorder = new MediaRecorder(stream);

    this.mediaRecorder.onstop = e => {
      this.zone.run(async () => { /// does this help the detection after setting the MyAudio source ??
        // this.thePlayer = new Audio();
        // this.myAudio = new Audio();

        this.isUploading = true;

        const blob = new Blob(this.chunks, { 'type': 'audio/ogg; codecs=opus' });
        // const blob = new Blob(this.chunks, { 'type': 'audio/webm' });
        // const blob = new Blob(this.chunks, { 'type': 'audio/mpeg' });


        this.chunks.length = 0;
        // this.thePlayer.src = window.URL.createObjectURL(blob);


        // push it to firebase storage.
        await this.blobUpService.pushBlobUpload(blob, this.theTagUid)
          .then(theNewURL => {
            // this.thePlayer.nativeElement.src = theNewURL;
            // this.thePlayer.nativeElement.load();

            this.myAudio = new Audio(theNewURL);
            this.myAudio.addEventListener('ended', this.playOrStop.bind(this));
            // this.myAudio.addEventListener('ended', function() { console.log('does this get called ??', this.isPlaying); this.isPlaying = false; });


            this.voiceNoteUrlEvent.emit({
              'theUrl': theNewURL
            });

            // audio.load();
            // audio.play();
            // console.log(this.thePlayer);
          })
          .catch(theError => {
            console.log('A Nasty Error occurred uploading the blob !! it is: ', theError);
          });

        this.isUploading = false;

      });
    };

    this.mediaRecorder.ondataavailable = e => this.chunks.push(e.data);
  }

  chosenMicSelection(theDeviceId: string) {
    console.log('In the chosen Mic selection and the event is: ', theDeviceId);
    if (theDeviceId !== 'default') {
      // get rid of default
      this.theDevices = this.theDevices.filter((d) => d.deviceId !== 'default');

      // create a new media recorder with the new device.
      navigator.mediaDevices.getUserMedia({ audio: { deviceId: { exact: theDeviceId } } })
        .then(stream => {
          console.log('is this the SECOND where I come after the stream ?');
          this.doCoolStuffWithTheStream(stream);
        })
        .catch(error => {
          console.log('ERROR occurred in the second stream ?');
        });

    }
  }

  public recordOrStop() {
    if (!this.isUploading) { // doesn't work while uploading.
      if (this.isRecording === true) {
        this.isRecording = false;
        this.mediaRecorder.stop();
      } else {
        this.isRecording = true;
        this.mediaRecorder.start();
      }
    }
  }


  public playOrStop() {
    console.log('In the play or stop !!! - isPlaying: ', this.isPlaying);
    if (this.isPlaying) {
      this.isPlaying = false;
      this.myAudio.pause();
    } else {
      this.isPlaying = true;
      this.myAudio.play();
    }
  }

}
