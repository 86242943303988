import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { AuthService } from '../auth/auth.service';
import { PackageDetailsModel } from '../models/package-details-model';

@Injectable()
export class PackageDetailsService {
  constructor(private afs: AngularFirestore, private authService: AuthService) { }


  getAllPacakagesDetail(): Observable<PackageDetailsModel[]> {

    let PackageDetailCollection: AngularFirestoreCollection<PackageDetailsModel>;

    if (this.authService.hasSysAdminPermissions()) {
      PackageDetailCollection = this.afs.collection('PackageDetails'); // reference
      return PackageDetailCollection.valueChanges().map(items => {
        console.log('Value Changes has returned in the list of package details (from service) !!', items);
        return items;
      });
    } else {
      console.log('THIS SHOULD NOT HAPPEN >>> ONLY SYS ADMINS SHOULD BE CALLING THIS - THROW AN EXCEPTION');
    }
  }


  // This is a comment

  // *********** start by creating the list page for package details and then the detail page for package details ********

  // async createNewPackageDetails(theNewJSON: any): Promise<any> {
  //   const PackageDetailCollection: AngularFirestoreCollection<PackageDetailsModel> = this.afs.collection('PackageDetails'); // reference

  //       // Persist a document id
  //       const newUid = this.afs.createId();

  //     const newPackageDetails: PackageDetailsModel = new PackageDetailsModel();

  //     newPackageDetails.fromJSON(theNewJSON);

  //     newPackageDetails.dateLastUpdated = new Date();

  //     await PackageDetailCollection.doc(newUid).set({ ...newPackageDetails })
  //       .then(() => {
  //         console.log('in the Package Details Service ... returned from adding a new Package Details, I don\'t get anything here so just return the Serial Number');
  //       })
  //       .catch(theError => {
  //         throw (theError);
  //       }); // This creates the doc with the id (don't use add)

  //     console.log('This is AFTER THE AWAIT ON THE DOC Set ... so it should come after the log from the .then !!!');

  //     return {
  //       'status': 'Successful',
  //       'uid': newUid
  //     };


  // }

  // getPackageDetails(theUid: string): Observable<PackageDetailsModel> {

  //   const thePackageDetailsDoc: AngularFirestoreDocument<PackageDetailsModel> = this.afs.doc('PackageDetails/' + theUid);

  //   return thePackageDetailsDoc.valueChanges().map(itemd => {
  //     console.log('Value Changes has returned in the single document (from Service) !!', itemd);
  //     return itemd;
  //   });
  // }

  // updatePackageDetailss(theUid: string, theUpdateJSON: any) {
  //   const thePackageDetailsDoc: AngularFirestoreDocument<PackageDetailsModel> = this.afs.doc('PackageDetails/' + theUid);

  //   theUpdateJSON.dateLastUpdated = new Date();

  //   return thePackageDetailsDoc.update(theUpdateJSON) // check returned values for errors
  //     .then(function () {
  //       console.log('The Package Details were successfully updated in the backend DB');
  //       return;
  //     })
  //     .catch(function (error) {
  //       console.log('there was an error updating the package details in the backend DB, the error is: ', error);
  //       throw (error);
  //     });
  // }

  // async deletePackageDetails(theUid: string) {

  //   const thePackageDetailsDoc: AngularFirestoreDocument<PackageDetailsModel> = this.afs.doc('PackageDetails/' + theUid);
  //   await thePackageDetailsDoc.delete();

  //   console.log('this message should come after all of the queries ... delete has hopefully succeeded');
  // }


}

