import { Component, OnInit, Input } from '@angular/core';
import { UserData } from '../models/user-data.model';
import { UserRoles } from '../models/user-roles.model';
import { UserMgmtService } from '../user-mgmt-service/user-mgmt-service.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';

@Component({
  selector: 'app-user-mgmt-detail',
  templateUrl: './user-mgmt-detail.component.html',
  styleUrls: ['./user-mgmt-detail.component.scss']
})
export class UserMgmtDetail implements OnInit {

  @Input()
  public userId;
  public existingUser: boolean;

  public loading = true;

  public userMgmtForm;

  // theUserMgmtDoc: AngularFirestoreDocument<UserMgmtModel>;
  theUserMgmt$: Observable<UserData>;


  constructor(
    public userMgmtService: UserMgmtService,
    private afs: AngularFirestore,
    private fb: FormBuilder,
    public theRoute: ActivatedRoute,
    public router: Router
  ) { }


  ngOnInit() {
    this.theRoute.params
      .map((params: Params) => params)
      .subscribe(params => this.userId = params.userId);

    console.log('the userMgmt doc id is: ', this.userId);

    this.userMgmtForm = this.fb.group({
      userFirstName: ['', [Validators.required]],
      userLastName: ['', [Validators.required]],
      userEmail: ['', [Validators.required]],
      password: '',
      userPhone: ''
    });

    if (this.userId === 'newuser') {
      console.log('creating a New User ... do I need anything here ??');
      this.loading = false;
    } else {
      this.theUserMgmt$ = this.userMgmtService.getUserData(this.userId).map(itemd => {
        console.log('Value Changes has returned in the User Data (component)!!', itemd);
        this.existingUser = itemd.existsInFirebase;
        this.loading = false;
        this.userMgmtForm.patchValue(itemd);
        return itemd;
      });

    }



  }

  saveChanges() {
    console.log('I\'m in the save changes method of the detail component, the form is: ', this.userMgmtForm.value);

    if (this.userId === 'newuser') {
      // need to create the user in Firebase !!!
      this.userMgmtService.createNewUser(this.userMgmtForm.value)
        .then(uid => {
          console.log('The new user was created and it\'s UID is: ', uid);
          this.router.navigate(['/admin/usermgmtlist']);
        })
        .catch(error => console.log('some stupid error occurred creating the user: ', error));

    } else {
      this.userMgmtService.updateUserData(this.userId, this.userMgmtForm.value);
      this.router.navigate(['/admin/usermgmtlist']);
    }

  }

  cancel() {
    this.router.navigate(['/admin/usermgmtlist']);
  }

}


