import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminHomePage } from './admin-home-page/admin-home-page.component';
import { LoginPage } from './login-page/login-page.component';
import { SysAdminGuard } from './auth/sysadmin.guard';
import { UserMgmtList } from './user-mgmt-list/user-mgmt-list.component';
import { UserMgmtDetail } from './user-mgmt-detail/user-mgmt-detail.component';
import { NotFoundComponent } from './utils/not-found/not-found.component';
import { SiteHomePage } from './site-home-page/site-home-page.component';
import { NewEvaluationFormComponent } from './new-evaluation-form/new-evaluation-form.component';
import { EvaluationsListPage } from './evaluations-list-page/evaluations-list-page.component';
import { EvaluationDetailPage } from './evaluation-detail-page/evaluation-detail-page.component';
import { AdminVoucherCodesComponent } from './admin-voucher-codes/admin-voucher-codes.component';


export const ROUTES: Routes = [
    { path: '', component: SiteHomePage },
    { path: 'login', component: LoginPage },

    { path: 'newevaluation/:method', component: NewEvaluationFormComponent},
    { path: 'evaluationslist/:listType', component: EvaluationsListPage},
    { path: 'evaluation/:evalId', component: EvaluationDetailPage},

    { path: 'admin', component: AdminHomePage, canActivate: [SysAdminGuard] },
    { path: 'admin/usermgmtlist', component: UserMgmtList, canActivate: [SysAdminGuard] },
    { path: 'admin/usermgmt/:userId', component: UserMgmtDetail, canActivate: [SysAdminGuard] },
    { path: 'admin/vouchermgmt', component: AdminVoucherCodesComponent, canActivate: [SysAdminGuard] },


    // { path: 'form/:theAgencyName', component: FormPage },
    { path: '**', component: NotFoundComponent }
];

