import { VoucherCodeUsageModel } from './voucher-code-usage-model';

export class VoucherCodeModel {

    constructor(

        public voucherCode: string = '', // this is the unique code imported fromt the generaro

        public state: string = 'unknown',  // should be 'unused', 'valid', 'expired', 'disabled', 'used', 'unpaid'

        public emailOfUser: string = '', // email of this user who assigned the voucher code

        public dateLoadedInSystem: Date = new Date(),
        public dateAssigned: Date = new Date('1970-01-01T00:00:00.000'),
        public assignedByUid: string = '',
        public assignedByName: string = '',

        public paidVoucher: boolean = false,
        public ammountPaid: number = 0,
        public datePaid: Date = new Date('1970-01-01T00:00:00.000'),
        public stripeTokenId: string = '',
        public stripeChargeTransactionId: string = '',

        public expirationDate: Date = new Date('2199-12-31T11:59:59.999'),
        public numberOfUsesAllowed: number = 0,

        public usageArray: VoucherCodeUsageModel[] = [],

        public sortIndex: number = 0

    ) { }


    public fromJSON(obj: any) {
        // console.log('in the from JSON of the WorkerTestscoreModel and the obj is: ', obj);
        for (const key in obj) {
            if (this.hasOwnProperty(key)) {

                this[key] = obj[key];

            }
        }
    }

}

