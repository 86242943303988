

export class EvaluatorModel {

    constructor(

        public uid: string = '',
        public name: string = '',
        public email: string = ''

    ) { }


    public fromJSON(obj: any) {
        // console.log('in the from JSON of the WorkerTestscoreModel and the obj is: ', obj);
        for (const key in obj) {
            if (this.hasOwnProperty(key)) {

                this[key] = obj[key];

            }
        }
    }

}
