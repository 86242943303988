import { Component, OnInit, HostListener } from '@angular/core';
import { VideoService } from '../video-service/video-service.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'video-timeline',
  templateUrl: './video-timeline.component.html',
  styleUrls: ['./video-timeline.component.scss']
})
export class VideoTimelineComponent implements OnInit {

  public lineSegments: number[] = [];
  public timeInSecsForSegments: number[] = [];

  private fullSegments = 0;

  // set these 2 values to control the spacing and divisions on the timeline.
  public pixelsPerSecMinFactor = 36;  // higher number results in more spacing.
  private insideSegments = 2







    ;

  // private finalAddOnInsideSegments;

  public theTimelineDiv;

  public pctOfTimeLine = 100;

  public pxPerSecond: number;

  constructor(public videoService: VideoService) {

  }

  ngOnInit() {
    console.log('The player total time is: ', this.videoService.totalTime);
    console.log('The player calculateWidth is: ', this.videoService.calculatedWidth);
    console.log('The player calculatedTotalWidth is: ', this.videoService.calculatedTotalWidth);

    for (let i = 0; i <= this.fullSegments; i++) {
      this.lineSegments.push(20);
      if (i < this.fullSegments) {
        for (let j = 1; j < this.insideSegments; j++) {
          this.lineSegments.push(15);
        }
      }
    }
    this.waitForPlayerToInit();
  }

  waitForPlayerToInit() {
    console.log('in the wait for player to init');
    if (this.videoService.totalTime > 0) {
      setTimeout(() => { this.setUpTimeLine(); }, 100);
    } else {
      setTimeout(() => { this.waitForPlayerToInit(); }, 100);
    }
  }

  onResize(event) {
    console.log('Width: ' + event.target.innerWidth);
    console.log(' ');
    this.setUpTimeLine();
  }

  setUpTimeLine() {
    console.log('in the video timeline - setUpTimeline');
    console.log('The player total time is: ', this.videoService.totalTime);
    console.log('The player calculated Duration is: ', this.videoService.calculatedDuration);
    console.log('The player calculateWidth is: ', this.videoService.calculatedWidth);
    console.log('The player calculatedTotalWidth is: ', this.videoService.calculatedTotalWidth);
    this.theTimelineDiv = document.getElementById('progressTimelineFull');
    const w = this.theTimelineDiv.offsetWidth;
    console.log('The timeline full Width is : ', w);


    this.pxPerSecond = (w / this.videoService.totalTime);
    console.log('Pixels per Second is: ', this.pxPerSecond);

    // get pps to at least 12
    const scrunchFactor = Math.ceil((this.pixelsPerSecMinFactor / this.pxPerSecond));
    const newPPS = this.pxPerSecond * scrunchFactor;
    console.log('new Pixels per Second is: ' + newPPS + ', with a scrunch factor of ' + scrunchFactor);
    console.log(' ');

    const wholeReduced = this.videoService.totalTime / scrunchFactor;
    console.log('The wholeReduced value is: ', wholeReduced);

    const wholeSecs = Math.floor(this.videoService.totalTime);
    const wholeCutoff = wholeSecs / scrunchFactor;
    console.log('The wholeCutoff value is: ', wholeCutoff);

    this.fullSegments = Math.floor(this.videoService.totalTime / scrunchFactor);
    const endSegment = wholeReduced - this.fullSegments;
    const finalAddOnInsideSegments = Math.floor(endSegment * this.insideSegments);  // deal with the partial segment at the end.

    this.pctOfTimeLine = (((this.fullSegments * scrunchFactor) + ((finalAddOnInsideSegments / this.insideSegments) * scrunchFactor)) / this.videoService.totalTime) * 100;
    console.log('video has ', + wholeSecs + ' whole seconds; and ' + this.fullSegments + ' full segments, with a scrunch factor of ' + scrunchFactor + ', and the end segment is: ' + endSegment);
    console.log('the percentage of the time line of the whole seconds is: ', this.pctOfTimeLine);


    this.lineSegments = [];
    this.timeInSecsForSegments = [];
    for (let i = 0; i <= this.fullSegments; i++) {
      this.lineSegments.push(15);
      if (i === this.fullSegments) {
        if (endSegment > 0.2) {
          this.timeInSecsForSegments.push(i * scrunchFactor);
        } else {
          this.timeInSecsForSegments.push(0);
        }
      } else {
        this.timeInSecsForSegments.push(i * scrunchFactor);
      }
      if (i < this.fullSegments) {
        for (let j = 1; j < this.insideSegments; j++) {
          this.lineSegments.push(10);
          this.timeInSecsForSegments.push(0);
        }
      } else { // if this is the last full segment ... put the extras
        for (let k = 1; k <= finalAddOnInsideSegments; k++) {
          this.lineSegments.push(10);
          this.timeInSecsForSegments.push(0);
        }
      }


      // if (i === 0 || i === this.fullSegments) {
      //   this.timeInSecsForSegments.push(0);
      // } else {
      //   this.timeInSecsForSegments.push(i * scrunchFactor);
      // }

    }
  }

}
