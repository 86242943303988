

export class EvaluationModel {

    constructor(

        public uid: string = '',
        public statusKey: number = 0,
        public firstName: string = '',
        public lastName: string = '',
        public email: string = '',
        public nccpNumber: string = '',
        public dateCreated: Date = new Date(),
        public creditCardOrVoucher: string = 'card',
        public voucherCodeUsed: string = '',
        public paid: boolean = false,
        public stripeTokenId: string = '',
        public stripeChargeTransactionId: string = '',
        public datePaid: Date = new Date('1-1-1970'),  // or date vouchere redeemed.
        public amountPaid: number = 0,
        public dateReceiptEmailSent: Date = new Date('1-1-1970'),
        public stripeChargeReceiptNumber: string = '',
        public creditCardBrand: string = 'unknown',
        public videoURL: string = '',
        public originalFileName: string = '',
        public dateVideoUploaded: Date = new Date('1-1-1970'),
        public videoFileSize: number = 0,
        public videoLength: number = 0,
        public dateAssigned: Date = new Date('1-1-1970'),
        public evaluatorAssignedUsername: string = '',
        public evaluatorAssignedUid: string = '',
        public dateCompleted: Date = new Date('1-1-1970'),
        // public datesViewed: Date[] = [],
        public PDFGeneratedDocumentURL: string = '',
        public PDFDateGenerated: Date = new Date('1-1-1970'),
        public PDFNeedsRegenerated: boolean = true,
        public datePDFSentToCustomer: Date = new Date('1-1-1970'),

        public packageChosen: string = '', // this should be an object at some point
        public evaluationQuestionsPackage: string = '',

        // general comments
        public evaluatorIntroComments: string = '',
        public evaluatorFinalcomments: string = '',

        // This may be a seperate object at some point as well
        public feedbackComments: string = '',
        public feedbackStandardCommentsArray: any[] = [],
        public timeOnTaskComments: string = '',
        public timeOnTaskStandardCommentsArray: any[] = [],
        public pedalogicalComments: string = '',
        public pedalogicalStandardCommentsArray: any[] = [],
        public skillAcquisitionComments: string = '',
        public generalComments: string = '',


    ) { }


    public fromJSON(obj: any) {
        // console.log('in the from JSON of the WorkerTestscoreModel and the obj is: ', obj);
        for (const key in obj) {
            if (this.hasOwnProperty(key)) {

                this[key] = obj[key];

            }
        }
    }

}
