import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(value: number, args: number[]): string {

    let precision = args[0];

    if (value === 0) {
      return '0 bytes';
    }
    if (!isFinite(value)) {
      return '-';
    }
    if (typeof precision === 'undefined') {
      precision = 1;
    }

    const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
    const numberValue = Math.floor(Math.log(value) / Math.log(1024));
    return (value / Math.pow(1024, Math.floor(numberValue))).toFixed(precision) + ' ' + units[numberValue];
  }

}
