

export class ItemUpload {
    uid: string;
    name: string;
    url: string;
    size: number;
    fullPath: string;

    owningUid: string;
    originalFileName: string;

    createdAt: Date = new Date();

    progress: number;
    bytesTransferred: number;

    file: File;
    constructor(file: File ) {
      this.file = file;
    }

    stripTemporaryFields(): any {
      const strippedItem: any = {};
      strippedItem.uid = this.uid;
      strippedItem.name = this.name;
      strippedItem.url = this.url;
      strippedItem.size = this.size;
      strippedItem.fullPath = this.fullPath;

      strippedItem.owningUid = this.owningUid;
      strippedItem.originalFileName = this.originalFileName;

      strippedItem.createdAt = this.createdAt;

      return strippedItem;
    }

    public fromJSON(obj: any) {
      // console.log('in the from JSON of the ItemUpload and the obj is: ', obj);
      for (const key in obj) {
          if (this.hasOwnProperty(key)) {

              this[key] = obj[key];

          }
      }
  }

  }
