import { Injectable } from '@angular/core';
import { UserData } from '../models/user-data.model';
import { UserRoles } from '../models/user-roles.model';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { map, take } from 'rxjs/operators';
import { Params } from '@angular/router';
import { AuthService } from '../auth/auth.service';

import * as _ from 'lodash';

@Injectable()
export class UserMgmtService {

  // private tempEvals: any[];
  // public tempEvals$: Observable<any[]>;
  // private evalsObserver;

  constructor(private afs: AngularFirestore, public authService: AuthService) {

    // this.tempEvals$ = new Observable(observer => {
    //   this.evalsObserver = observer;
    // });
  }


  getAllUsers(): Observable<UserData[]> {

    const userMgmtCollection: AngularFirestoreCollection<UserData> = this.afs.collection('Users'); // reference

    return userMgmtCollection.valueChanges().pipe(map(items => {
      console.log('Value Changes has returned users in the list (from service) !!', items);
      return items;
    }));    // observable of users data

  }

  getAllRoles(): Observable<UserRoles[]> {
    const userRolesCollection: AngularFirestoreCollection<UserRoles> = this.afs.collection('Roles'); // reference
    return userRolesCollection.valueChanges().pipe(map(items => {
      console.log('Value Changes has returned roles in the list (from service) !!', items);
      return items;
    }));    // observable of users data
  }

  // getAllEvaluators(): Observable<any[]> {
  //   const userRolesCollection: AngularFirestoreCollection<UserRoles> = this.afs.collection('Roles'); // reference

  //   userRolesCollection.valueChanges().pipe(
  //     map(items => {
  //       console.log('Value Changes has returned roles in the list (from service) !!', items);
  //       const temp: any[] = [];
  //       const evaluatorUids: any[] = [];

  //       for (const itemd of items) {
  //         if (itemd.evaluator) {
  //           evaluatorUids.push({ 'uid': itemd.uid });
  //         }
  //       }

  //       console.log('the evaluatorUids array is: ', evaluatorUids);
  //       const userDataCollection: AngularFirestoreCollection<UserData> = this.afs.collection('Users');
  //       userDataCollection.valueChanges().pipe(
  //         take(1),
  //         map(users => {
  //           console.log('users', users);
  //           const newArray = _.intersectionBy(users, evaluatorUids, 'uid');
  //           console.log('new Users Array: ', newArray);

  //           for (const aUser of _.intersectionBy(users, evaluatorUids, 'uid')) {

  //             const tu: any = {};
  //             if (aUser.lastNaem === '') {
  //               tu.name = aUser.userDisplayName;
  //             } else {
  //               tu.name = aUser.userFirstName + ' ' + aUser.userLastName;
  //             }
  //             tu.email = aUser.userEmail;
  //             tu.uid = aUser.uid;
  //             temp.push(tu);
  //           }

  //           this.evalsObserver.next(temp);
  //         })).subscribe();

  //     })).subscribe();

  //   return this.tempEvals$;
  // }

  //  newUserMgmt(): Observable<UserData> {
  createNewUser(formValues: any): Promise<string> {

    // Want to REDO THIS !!!!
    const userMgmtCollection: AngularFirestoreCollection<UserData> = this.afs.collection('Users'); // reference
    const userRolesCollection: AngularFirestoreCollection<UserRoles> = this.afs.collection('Roles'); // reference

    let theTempPassword: string;
    if (formValues.password === '') {
      theTempPassword = 'temp11';
    } else {
      theTempPassword = formValues.password;
    }

    return this.authService.createNewUser(formValues.userEmail, theTempPassword)
      .then(uid => {
        console.log('The new user was created :-) and it\'s UID is: ', uid);

        // Persist a document id
        const newUserMgmt: UserData = new UserData();
        newUserMgmt.uid = uid;
        newUserMgmt.userFirstName = formValues.userFirstName;
        newUserMgmt.userLastName = formValues.userLastName;
        newUserMgmt.userEmail = formValues.userEmail;
        newUserMgmt.userPhone = formValues.userPhone;
        newUserMgmt.createdBy = this.authService.currentUserId();
        newUserMgmt.dateCreated = new Date();

        userMgmtCollection.doc(uid).set({ ...newUserMgmt }); // This creates the doc with the id (don't use add)

        const newUserRoles: UserRoles = new UserRoles();
        newUserRoles.uid = uid;
        userRolesCollection.doc(uid).set({ ...newUserRoles }); // This creates the doc with the id (don't use add)

        return uid;
      })
      .catch(error => {
        console.log('An error occurred creating the New User in Firebase :-( the error was: ', error);
        return error;
      });




  }

  createNewUserData(id: string) {
    const userMgmtCollection: AngularFirestoreCollection<UserData> = this.afs.collection('Users'); // reference

    // Persist a document id

    const newUserMgmt: UserData = new UserData();
    newUserMgmt.uid = id;
    userMgmtCollection.doc(id).set({ ...newUserMgmt }); // This creates the doc with the id (don't use add)

  }

  createNewUserRoles(id: string) {
    const userRolesCollection: AngularFirestoreCollection<UserRoles> = this.afs.collection('Roles'); // reference

    // Persist a document id

    const newUserRoles: UserRoles = new UserRoles();
    newUserRoles.uid = id;
    userRolesCollection.doc(id).set({ ...newUserRoles }); // This creates the doc with the id (don't use add)
  }

  getUserData(theUserId: string): Observable<UserData> {

    const theUserMgmtDoc: AngularFirestoreDocument<UserData> = this.afs.doc('Users/' + theUserId);

    return theUserMgmtDoc.valueChanges().map(itemd => {
      console.log('Value Changes has returned in the single user document (from Service) !!', itemd);
      return itemd;
    });
  }

  getRoleData(theUserId: string): Observable<UserRoles> {

    const theUserRolesDoc: AngularFirestoreDocument<UserRoles> = this.afs.doc('Roles/' + theUserId);

    return theUserRolesDoc.valueChanges().map(itemd => {
      console.log('Value Changes has returned in the single user roles (from Service) !!', itemd);
      return itemd;
    });
  }

  updateUserData(theUserId: string, theUpdateJSON: any) {
    const theUserMgmtDoc: AngularFirestoreDocument<UserData> = this.afs.doc('Users/' + theUserId);
    return theUserMgmtDoc.update(theUpdateJSON) // check returned values for errors
      .then(function () {
        console.log('The user data was successfully updated in the backend DB');
      })
      .catch(function (error) {
        console.log('there was an error updating the user data in the backend DB, the error is: ', error);
      });
  }

  async updateUserRoles(theUserId: string, theUpdateJSON: any) {
    const theUserRolesDoc: AngularFirestoreDocument<UserRoles> = this.afs.doc('Roles/' + theUserId);
    return theUserRolesDoc.update(theUpdateJSON) // check returned values for errors
      .then(function () {
        console.log('The user roles were successfully updated in the backend DB');
        return ('success');
      })
      .catch(function (error) {
        console.log('there was an error updating the user roles in the backend DB, the error is: ', error);
        return error;
      });
  }

  deleteUserData(theUserId: string) {
    console.log('attempting to delete the user documents for the user with user id: ', theUserId);
    const theUserMgmtDoc: AngularFirestoreDocument<UserData> = this.afs.doc('Users/' + theUserId);
    theUserMgmtDoc.delete()
      .then(function () {
        console.log('The User Data was successfully deleted in the backend DB');
      })
      .catch(function (error) {
        console.log('there was an error deleting the User Data in the backend DB, the error is: ', error);
      });

    const theUserRolesDoc: AngularFirestoreDocument<UserRoles> = this.afs.doc('Roles/' + theUserId);
    theUserRolesDoc.delete()
      .then(function () {
        console.log('The User Roles were successfully deleted in the backend DB');
      })
      .catch(function (error) {
        console.log('there was an error deleting the User Roles in the backend DB, the error is: ', error);
      });
  }

}
