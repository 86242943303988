import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-nav-top',
  templateUrl: './nav-top.component.html',
  styleUrls: ['./nav-top.component.scss']
})
export class NavTopComponent {

  show = false;

  constructor(public router: Router, public authService: AuthService) { }

  toggleCollapse() {
    this.show = !this.show;
  }

}
