
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EvaluatorModel } from '../models/evaluator-model';
import { AuthService } from '../auth/auth.service';
import { firestore } from '@firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class EvaluatorsListService {

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService
  ) { }

  getEvaluatorsList(): Observable<EvaluatorModel[]> {

      const evaluatorsListCollection: AngularFirestoreCollection<EvaluatorModel> = this.afs.collection('EvaluatorsList'); // reference


    return evaluatorsListCollection.valueChanges().pipe(map(items => {
      console.log('Value Changes has returned in the list of evaluator(from service) !!', items);
      return items.map(itemd => {
        // do something to the records here if you like.
        return itemd;
      });
    }));   // observable of all Ray buttons data
    // .share();

  }

  async addEvaluatorToList(theEvaluator: EvaluatorModel): Promise<any> {
    const evaluatorsListCollection: AngularFirestoreCollection<EvaluatorModel> = this.afs.collection('EvaluatorsList'); // reference

    console.log('in the Evaluator Service Create Eval ... and the input Object is: ', theEvaluator);

    await evaluatorsListCollection.doc(theEvaluator.uid).set({ ...theEvaluator })
      .then(() => {
        console.log('in the Evaluator List Service ... returned from adding a new Evaluator, I don\'t get anything here so just return the UID');
      })
      .catch(theError => {
        throw (theError);
      }); // This creates the doc with the id (don't use add)

    console.log('This is AFTER THE AWAIT ON THE DOC Set ... so it should come after the log from the .then !!!');
    return { 'uid': theEvaluator.uid };

  }


  updateEvaluatorDetials(theDocId: string, theUpdateJSON: any) {
    // Probaby won't use this one ever.
    const theEvaluationDoc: AngularFirestoreDocument<EvaluatorModel> = this.afs.doc('EvaluatorsList/' + theDocId);

    theUpdateJSON.dateLastUpdated = new Date();

    return theEvaluationDoc.update(theUpdateJSON) // check returned values for errors
      .then(function () {
        console.log('The Evaluator detail was successfully updated in the backend DB');
        return;
      })
      .catch(function (error) {
        console.log('there was an error updating the Evaluator details in the backend DB, the error is: ', error);
        throw (error);
      });
  }


  async removeEvaluatorFromList(theEvaluatorId: string) {

    const theEvaluationDoc: AngularFirestoreDocument<EvaluatorModel> = this.afs.doc('EvaluatorsList/' + theEvaluatorId);

    await theEvaluationDoc.delete()
    .then(function () {
      console.log('The Evaluator ' + theEvaluatorId + ' was successfully removed fromt the list');
      return;
    })
    .catch(function (error) {
      console.log('there was an error removing the Evaluator in the backend DB, the error is: ', error);
      throw (error);
    });

  }




}
