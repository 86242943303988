import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scrollTimeDisplay'
})
export class ScrollTimeDisplayPipe implements PipeTransform {

  transform(value: number, args: string[]): string {
    const hh = Math.floor(value / 3600);
    const mm = Math.floor(value / 60) % 60;
    // var ss = Math.floor(value) % 60;
    const sss = (Math.floor(value * 10) % 600) / 10;

    // console.log("transfoming a time of : " + value + " into: " +
    // hh + ":" + (mm < 10 ? "0" : "") + mm + ":" + (sss < 10 ? "0" : "") + sss);

    // return hh + ":" + (mm < 10 ? "0" : "") + mm + ":" + (ss < 10 ? "0" : "") + ss;
    // return (hh > 0 ? hh + ':' : '') + (mm > 0 ? mm + ':' : '') + ((sss < 10) && (mm > 0) ? '0' : '') + sss;
    return (hh > 0 ? hh + ':' : '') + ((hh > 0) && (mm < 10) ? '0' + mm + ':' : (mm > 0 ? mm + ':' : '')) + ((sss < 10) && (mm > 0) ? '0' : '') + sss;
  }

}
