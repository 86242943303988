import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EvaluationModel } from '../models/evaluation-model';
import { AuthService } from '../auth/auth.service';
import { firestore } from '@firebase/firestore';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EvaluationsService {

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private http: HttpClient
  ) { }

  getAllEvaluations(evaluationType: string): Observable<EvaluationModel[]> {

    let evaluationDataCollection: AngularFirestoreCollection<EvaluationModel>;
    if (this.authService.hasSysAdminPermissions()) {
      evaluationDataCollection = this.afs.collection('Evaluations'); // reference
    } else if (this.authService.hasEvaluatorPermissions() && evaluationType === 'evaluator') {
      evaluationDataCollection = this.afs.collection('Evaluations', ref => ref.where('evaluatorAssignedUid', '==', this.authService.currentUserId()));
    } else {
      evaluationDataCollection = this.afs.collection('Evaluations', ref => ref.where('email', '==', this.authService.currentUserEmail()));
    }

    return evaluationDataCollection.valueChanges().pipe(map(items => {
      console.log('Value Changes has returned in the list of evaluations(from service) !!', items);
      return items.map(itemd => {
        itemd.dateVideoUploaded = (itemd.dateVideoUploaded as firestore.Timestamp).toDate();
        itemd.dateCreated = (itemd.dateCreated as firestore.Timestamp).toDate();
        itemd.datePaid = (itemd.datePaid as firestore.Timestamp).toDate();
        itemd.dateReceiptEmailSent = (itemd.dateReceiptEmailSent as firestore.Timestamp).toDate();
        itemd.dateAssigned = (itemd.dateAssigned as firestore.Timestamp).toDate();
        itemd.dateCompleted = (itemd.dateCompleted as firestore.Timestamp).toDate();
        itemd.datePDFSentToCustomer = (itemd.datePDFSentToCustomer as firestore.Timestamp).toDate();
        return itemd;
      });
    }));   // observable of all Ray buttons data
    // .share();

  }

  async createNewEvaluation(theNewJSON: any): Promise<any> {
    const evaluationDataCollection: AngularFirestoreCollection<EvaluationModel> = this.afs.collection('Evaluations'); // reference

    console.log('in the Evaluations Service Create Eval ... and the input JSON is: ', theNewJSON);
    // Persist a document id
    let newUid: string;

    if (theNewJSON.uid) {
      newUid = theNewJSON.uid;
    } else {
      newUid = this.afs.createId();
    }

    // const newUid = this.afs.createId();

    const newEvaluationData: EvaluationModel = new EvaluationModel();
    newEvaluationData.uid = newUid;

    newEvaluationData.fromJSON(theNewJSON);

    newEvaluationData.dateCreated = new Date();

    await evaluationDataCollection.doc(newUid).set({ ...newEvaluationData })
      .then(() => {
        console.log('in the Evaluations Service ... returned from adding a new Evaluation, I don\'t get anything here so just return the UID');
      })
      .catch(theError => {
        throw (theError);
      }); // This creates the doc with the id (don't use add)

    console.log('This is AFTER THE AWAIT ON THE DOC Set ... so it should come after the log from the .then !!!');
    return { 'uid': newUid };

  }


  getEvaluation(theDocId: string): Observable<EvaluationModel> {

    const theEvaluationDoc: AngularFirestoreDocument<EvaluationModel> = this.afs.doc('Evaluations/' + theDocId);

    return theEvaluationDoc.valueChanges().map(itemd => {
      console.log('Value Changes has returned in the single evaluation doc (from Service) !!', itemd);
      itemd.dateVideoUploaded = (itemd.dateVideoUploaded as firestore.Timestamp).toDate();
      itemd.dateCreated = (itemd.dateCreated as firestore.Timestamp).toDate();
      itemd.datePaid = (itemd.datePaid as firestore.Timestamp).toDate();
      itemd.dateReceiptEmailSent = (itemd.dateReceiptEmailSent as firestore.Timestamp).toDate();
      itemd.dateAssigned = (itemd.dateAssigned as firestore.Timestamp).toDate();
      itemd.dateCompleted = (itemd.dateCompleted as firestore.Timestamp).toDate();
      itemd.datePDFSentToCustomer = (itemd.datePDFSentToCustomer as firestore.Timestamp).toDate();
      return itemd;
    });
  }

  updateEvaluation(theDocId: string, theUpdateJSON: any) {
    const theEvaluationDoc: AngularFirestoreDocument<EvaluationModel> = this.afs.doc('Evaluations/' + theDocId);

    theUpdateJSON.dateLastUpdated = new Date();

    return theEvaluationDoc.update(theUpdateJSON) // check returned values for errors
      .then(function () {
        console.log('The Evaluation was successfully updated in the backend DB');
        return;
      })
      .catch(function (error) {
        console.log('there was an error updating the Evaluation in the backend DB, the error is: ', error);
        throw (error);
      });
  }


  async deleteEvaluation(theDocId: string) {

    const theEvaluationDoc: AngularFirestoreDocument<EvaluationModel> = this.afs.doc('Evaluations/' + theDocId);

    await theEvaluationDoc.delete()
    .then(function () {
      console.log('The Evaluation ' + theDocId + ' was successfully deleted from the backend DB');
      return;
    })
    .catch(function (error) {
      console.log('there was an error deleting the Evaluation in the backend DB, the error is: ', error);
      throw (error);
    });

  }



  // generatePDFOnServer(theDocId: string): Promise<any> {


  //   const tempURL = 'https://us-central1-practice-evaluation-3.cloudfunctions.net/generatePDFReport';

  //   const httpBody: any = {};
  //   httpBody.evalUid = theDocId;

  //   return new Promise((resolve, reject) => {
  //     console.log('In Evaluation Service generating the PDF ... and the httpBody that will be sent is: ', httpBody);

  //     this.http
  //       .post(tempURL, httpBody)
  //       .subscribe(
  //         data => {
  //           console.log('Evaluation Service generating the PDF after the HTTP call');
  //           const resp: any = data;
  //           console.log('Evaluation Service generating the PDF after the HTTP call and the tempResponse is: ', resp);

  //           if (resp.result === 'success') {
  //             resolve({
  //               result: 'success',
  //               details: 'Generated PDF Successfully - URL Attached',
  //               linkURL: resp.linkURL
  //             });
  //           } else {
  //             resolve({
  //               result: 'failure',
  //               details: 'Generate PDF Failed'
  //             });
  //           }

  //         },
  //         error => {
  //           console.log('In the error portion after the call to the backend to call for generating the PDF and the error is: ', error);
  //           reject({
  //             result: 'failure',
  //             details: 'Generate PDF Failed',
  //             returnedError: error
  //           });
  //         }
  //       );
  //   });
  // }



  // async generatePDFLocally(theEvaluation: EvaluationModel) {
  //   console.log('in the generatePDFReport endpoint ... V1.0.0.1');
  //   // console.log('The req body is: ', req.body);
  //   try {
  //       console.log('DO something HERE ');
  //       // const tagsForEval = admin.firestore().collection('MarkupTags')
  //       //     .where('evaluationId', '==', req.body.evalUid)
  //       //     .orderBy('markStart', 'asc');

  //       // const evalTagsArray: any[] = [];
  //       // const tagsFullSnapshot = await tagsForEval.get();

  //       // tagsFullSnapshot.forEach(docSnap => {
  //       //     const tempDoc = docSnap.data();
  //       //     evalTagsArray.push(tempDoc);
  //       // })

  //       // const theEvaluation = await admin.firestore().collection('Evaluations').doc(evalUid).get()
  //       //     .then(docsnap => {
  //       //         return docsnap.data();
  //       //     });


  //       const PDFDocument = require('pdfkit');
  //       // const blobStream = require('blob-stream');
  //       const fs = require('fs');

  //       const titleFontSize = 25;
  //       const bigTextSize = 15;
  //       const regTextSize = 12;
  //       const smallTextSize = 8;

  //       const smallYGap = 6;
  //       const medYGap = 12;
  //       const largeYGap = 18;

  //       const grapicWidth = 54;
  //       const graphicGap = 36;
  //       const leftMargin = 50;
  //       const rightMargin = 50;
  //       const topMargin = 36;
  //       const botMargin = 72;
  //       const innerMargin = leftMargin + grapicWidth + graphicGap;
  //       const innerWidth = 200;

  //       const runningY = 0;
  //       const boxStartingY = 0;
  //       const currentPage = 1;

  //       const boxHeight = 100;
  //       const boxMiddleX = 0;
  //       const boxMiddleY = 0;
  //       const textStartX = 0;
  //       const textStartY = 0;
  //       const holdingY = 0;

  //       const heading = 'HEADING';
  //       const sectionHeadingsShort = ['FEEDBACK', 'TIME on TASK', 'PEDALOGICAL', 'SKILLS'];
  //       const sectionHedingFillColors = ['green', 'red', 'yellow', 'skyblue'];

  //       const currentSection = 0;
  //       const headingSize = 4;
  //       const headingMargin = 5;
  //       const skipText = false;


  //       // Try the PDF kit stuff here
  //       // const doc = new PDFDocument({
  //       //     size: 'LETTER',
  //       //     bufferPages: true,
  //       //     margins: {
  //       //         top: topMargin,
  //       //         bottom: botMargin,
  //       //         left: leftMargin,
  //       //         right: rightMargin
  //       //     }
  //       // });

  //       // const localFilePath = path.join(os.tmpdir(), 'Eval_Report.pdf');
  //       // const stream = doc.pipe(blobStream());

  //       const doc = new PDFDocument;
  //       doc.pipe(fs.createWriteStream('/Users/BAS/temp1/eval_report.pdf'));

  //       doc.text('Hello world!', 100, 100);

  //       // doc.info['Title'] = "Evaluator's Report";
  //       // doc.info['Author'] = theEvaluation.evaluatorAssignedUsername;
  //       // doc.info['Subject'] = 'PracticeEvaluation.com Evaluation';
  //       // doc.info['ModDate'] = theEvaluation.dateCompleted;
  //       // doc.info['CreationDate'] = theEvaluation.dateAssigned;

  //       // // Write headers
  //       // //res.writeHead(200, {
  //       // //    'Content-Type': 'application/pdf'
  //       // //});
  //       // // Pipe generated PDF into response
  //       // // doc.pipe(res);

  //       // // draw some text
  //       // doc.fontSize(titleFontSize)
  //       //     .text("EVALUATOR'S REPORT", { align: 'center' })
  //       //     .fontSize(regTextSize)
  //       //     .text('Prepared for: ' + theEvaluation.firstName + ' ' + theEvaluation.lastName, { align: 'center' })
  //       //     .moveDown()
  //       //     .fontSize(smallTextSize)
  //       //     .text('Prepared by: ' + theEvaluation.evaluatorAssignedUsername, { align: 'left' })
  //       //     .moveUp()
  //       //     .text('Prepared on: ' + theEvaluation.dateCompleted, { align: 'right' });

  //       // runningY = doc.y;
  //       // runningY += smallYGap;

  //       // doc.moveTo(leftMargin - 5, runningY)
  //       //     .lineTo(doc.page.width - rightMargin + 5, runningY)
  //       //     .stroke();

  //       // //runningY = doc.y + largeYGap;
  //       // innerWidth = doc.page.width - rightMargin - innerMargin;


  //       doc.end();


  //       // const storage = admin.storage()
  //       // // 10 years
  //       // const expiresAt = Date.now() + 316000000000;

  //       // const destination = 'VideoFiles/' + evalUid + '/Eval_Report.pdf';

  //       // storage
  //       //     .bucket('practice-evaluation-3.appspot.com')
  //       //     .upload(localFilePath, { destination })
  //       //     .then(async individualResult => {
  //       //         console.log('What do I get back from a single file upload? : ', individualResult);
  //       //         const theNewFile = individualResult[0];
  //       //         console.log('the whole file is: ', theNewFile);
  //       //         console.log('the file name is : ', theNewFile.name);
  //       //         // console.log('The URL should be at: ', theNewFile.getSignedUrl);
  //       //         // console.log('The MetaData is: ', theNewFile.metadata);
  //       //         // console.log('The URL should be at: ', theNewFile.metadata.mediaLink);

  //       //         const theSUrl = await theNewFile.getSignedUrl({
  //       //             action: 'read',
  //       //             expires: expiresAt
  //       //         });
  //       //         console.log('The URL returned is: ', theSUrl[0]);


  //       //         admin.firestore().collection('Evaluations').doc(evalUid)
  //       //             .update({
  //       //                 'PDFGeneratedDocumentURL': theSUrl[0],
  //       //                 'PDFDateGenerated': new Date(),
  //       //                 'PDFNeedsRegenerated': false
  //       //             })
  //       //             .then(updateResult => {
  //       //                 console.log('The pdf was created and uploaded')
  //       //                 fs.unlinkSync(localFilePath);

  //       //                 res.status(200).send({
  //       //                     result: 'success',
  //       //                     reason: 'PDF was generated and saved to storage - link attached.',
  //       //                     linkURL: theSUrl[0]
  //       //                 });

  //       //             })
  //       //             .catch(err => console.error('ERROR inside firestore update with PDF URL: ', err));


  //       //         // return individualResult;
  //       //         // return theSUrl[0];
  //       //     })
  //       //     .catch(err => console.error('ERROR inside upload of PDF: ', err));




  //   } catch (error) {
  //       console.log('error occurred in the generatePDFReport function and it is: ', error);
  //       // res.status(424).send({
  //       //     result: 'ERROR',
  //       //     error: error,
  //       // });
  //   }

  // }




}
