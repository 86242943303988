
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { switchMap, map } from 'rxjs/operators';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

import { Source, Charge } from '../models';



@Injectable()
export class PaymentService {

  readonly api = `${environment.functionsURL}/app`;

  public stripe = Stripe(environment.stripePublishable); // this should be private and all done here.
  public elements: any;

  constructor(private http: HttpClient) {
    this.elements = this.stripe.elements();
  }

  ///// PAYMENT ACTIONS ////


  createCharge(card: any, amount: number): Observable<Charge> {
    const url = `${this.api}/charges/`;

    return fromPromise<Source>( this.stripe.createSource(card) ).pipe(
      switchMap(data => {
        return this.http.post<Charge>(url, { amount, sourceId: data.source.id });
      })
    );
  }

  // Call backend in Firebase to charge card with token
    async callBackendToMakeActualCharge(token: string, evalId: string, currency: string, amount: number, email: string): Promise<any> {

    const tempURL = 'https://us-central1-practice-evaluation-3.cloudfunctions.net/httpChargeCardWithStripe';

    const httpBody: any = {};
    httpBody.token = token;
    httpBody.evalId = evalId;
    httpBody.currency = currency;
    httpBody.amount = amount;
    httpBody.email = email;

    return new Promise((resolve, reject) => {

      this.http
        .post(tempURL, httpBody)
        .subscribe(
          data => {
            const resp: any = data;
            console.log('In the Payment Service after the HTTP call to charge the card and the tempResponse is: ', resp);

            if (resp.result === 'successful charge') {

              // let responseDataPM25: any = {};
              // let responseDataCO: any = {};

              // parse this and rip it down to PM2.5 & CO
              // if (resp.resultAQArray.length > 0) {


              // }


              resolve({
                'status': 'Success',
                'chargeId': resp.chargeId,
                'chargeOutcome': resp.chargeOutcome,
                'chargeAmount': resp.chargeAmount,
                'chargePaidBoolean': resp.chargePaidBoolean,
                'receiptNumber': resp.chargeReceiptNumber
              });

            } else {
              resolve({
                'status': 'ERROR calling out to Stripe',
                'chargeId': resp.id,
                'chargeOutcome': resp.chargeOutcome,
                'chargeAmount': resp.chargeAmount,
                'chargeFraud': resp.chargeFraud,
                'chargePaidBoolean': resp.chargePaidBoolean,
                'chargeFailureCode': resp.chargeFailureCode,
                'chargeFailureMessage': resp.chargeFailureMessage,
              });
            }

          },
          error => {
            console.log('In the error portion after the call to the backend to call Stripe for the actual charge and the error is: ', error);
            reject(error);
          }
        );
    });
  }


}
