import { Injectable } from '@angular/core';
import { MarkupTagService } from '../markup-tag-service/markup-tag-service.service';
import { MarkupTagTextValuesService } from '../markup-tag-text-values-service/markup-tag-text-values-service.service';
import { ComponentFixture } from '@angular/core/testing';
import { AngularFirestore } from 'angularfire2/firestore';

import * as html2canvas from 'html2canvas';
import * as CVF from 'capture-video-frame';
import { EvaluationsService } from '../evaluations-service/evaluations-service.service';

// const cors = require('cors')({ origin: true });

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  public evaluationId: string;

  public videoElement: any;
  public divPlayerContainer: any;
  // public divPlayerContainerHeight:number = 500;
  public divFullPlayer: any;
  public divProgressMeterFull: any;
  public divTheWholeComponentInner: any;
  public divTheWholeComponentOuter: any;

  public audioPlayerTagElement: any;

  public currentPath = '';
  public currentTitle = 'loading ...';
  public currentTime = 0;
  public totalTime = 0;
  public calculatedWidth: number;
  public calculatedTotalWidth: number;
  public calculatedDuration: number;
  public calculatedScrubY: number;
  public isMuted = false;
  public isPlaying = false;
  public isAtStartOrEnd = true;
  public isDragging = false;
  public volume = 5;

  // make a class for the markup rows
  public markupArray: any[] = [];    // this as the array object
  private markupIndexActive = -1;
  private markupIndexToDelete = -1;
  public markupArrayLoaded = false;

  public activeItem: any = {};

  // public grabbedFrame: any;

  //     public minimalTableConfig: TableConfigModel = {
  //     columnDefs: [
  //       { field: 'markStart' },
  //       { field: 'markType' },
  //       { field: 'markText' }
  //     ]
  //   }

  private tempProgressMeterOffsetLeft;
  private dragX;
  // private dragY;
  private howManyTimes1 = 0;
  private howManyTimes2 = 0;

  private intervalCount = 0;     // number of seconds for the interval countdown
  private intervalId: any;             // keep the interval ID so it can be cancelled.
  private intervalLength = 5;    // in seconds for 1000ms interval
  private pauseToReadLength = 3; // in seconds
  public playbackResumesIn = 0;
  private previouslyPlaying = false;
  private tagWasClickedDirectly = false;

  // for entering a new tag
  public tagDataValuesLoaded = false;
  public theTagDataValuesArray: any[];
  public newItemEntry: any = {};
  public showNewTagEntryScreen = false;
  public newTagEntryErrorMessage: any;

  constructor(
    private videoMarkupService: MarkupTagService,
    private tagDataValues: MarkupTagTextValuesService,
    private evalService: EvaluationsService,
    private afs: AngularFirestore) {

    this.tagDataValues.getTagDataValues('CoreTagValues').subscribe(returnedInfo => {

      this.theTagDataValuesArray = returnedInfo;
      console.log('Retrieved the Data Tag Texts and the array is: ', this.theTagDataValuesArray);
      this.tagDataValuesLoaded = true;
    });

  }

  appSetup(videoElement: string, theInputVideoLocation: string) {
    console.log('in App Setup NOW!! ');
    this.videoElement = <HTMLVideoElement>document.getElementById(videoElement);
    this.videoElement.addEventListener('loadedmetadata', this.updateData);
    this.videoElement.addEventListener('timeupdate', this.updateTime);
    this.videoElement.addEventListener('ended', this.videoEnded);


    // this.currentPath = "https://s3.amazonaws.com/pecomtestbuckettwo/testuploads/Fullcourt_Mix_Drills.mp4";
    // this.currentPath = "https://s3.amazonaws.com/pecomtestbuckettwo/testuploads/LakerCutCueandRead.mp4"
    // this.currentPath = "https://ia902505.us.archive.org/7/items/Birds021/Birds%2002-1.m4v"
    // this.currentPath = '/assets/videos/LakerCutCueandRead.mp4';
    // *** set the currentPath to the video coming into the component from the parent in the parent component
    this.currentPath = theInputVideoLocation;

    this.currentTitle = 'Evaluation Video';

    this.volume = this.videoElement.volume * 10;
    // console.log("in App Setup and the volume is :", this.videoElement.volume);

    // window.setInterval(this.timerFired, 500);

    // maybe move this up to App Setup
    // console.log("in updateData and about to call getAllVideoMarkupTagsForEvaluation : ", this.evaluationId);
    this.videoMarkupService.getAllVideoMarkupTagsForEvaluation(this.evaluationId).subscribe(
      returnedInfo => {
        console.log('in updateData and called getAllVideoMarkupTagsForEvaluation : ', returnedInfo);
        this.markupArray = returnedInfo;
        // Sort the array.
        this.markupArray.sort(function (a, b) {
          return a.markStart - b.markStart;
        });

        this.markupArrayLoaded = true;

      }
    );




  }

  updateData = (e: any) => {
    this.totalTime = this.videoElement.duration;

    this.divPlayerContainer = document.getElementById('playerContainer');
    this.divFullPlayer = document.getElementById('fullPlayer');
    this.divProgressMeterFull = document.getElementById('progressMeterFull');

    this.divTheWholeComponentInner = document.getElementById('theWholeComponentDiv');
    this.divTheWholeComponentOuter = document.getElementById('theWholeThingEval');

    this.audioPlayerTagElement = document.getElementById('audioPlayerTag');
    console.log('the audio player element is: ', this.audioPlayerTagElement);

    // this.divPlayerContainerHeight
    //     = this.divPlayerContainer.clientHeight - document.getElementById("scrolling-list-header").clientHeight -16;

    this.timerFired();

  }

  seekToTimeInSec(s: number) {
    this.videoElement.currentTime = s;
    this.currentTime = s;
  }


  seekVideo(e: any) {

    const w = document.getElementById('progressMeterFull').offsetWidth;
    console.log('The progress meter full Width is : ', w);
    // console.log("The event page X value is : ", e.pageX);
    // console.log("The event offset X value is : ", e.offsetX);
    // console.log("the event LayerX value is : ", e.layerX)
    // console.log("The event  X value is : ", e.x);
    // console.log("The whole event is : ", e);
    // console.log("The progress meter full element is : ", this.divProgressMeterFull);
    console.log('The progress meter offset left is : ', this.divProgressMeterFull.offsetLeft);
    // console.log("The player container element is : ", this.divPlayerContainer);
    // console.log("The player container offset left is : ", this.divPlayerContainer.offsetLeft);
    // console.log("The full player element is : ", this.divFullPlayer);
    console.log('The full player offsetLeft is : ', this.divFullPlayer.offsetLeft);
    // console.log("The video element is : ", this.videoElement);
    console.log('The whole thing inner offsetLeft is : ', this.divTheWholeComponentInner.offsetLeft);
    console.log('The whole thing outer offsetLeft is : ', this.divTheWholeComponentOuter.offsetLeft);

    // let d = this.videoElement.duration;
    this.calculatedTotalWidth = this.videoElement.offsetWidth; // try making this W
    this.calculatedDuration = this.videoElement.duration;
    // let s = Math.round(((e.clientX - this.divProgressMeterFull.offsetLeft - this.divPlayerContainer.offsetLeft) / w)*this.calculatedDuration);
    // Use LayerX for now - not working as LayerX is picking up the handle layer and jumping back to the beginning.
    // const s = Math.round(((e.layerX + 5) / w) * this.calculatedDuration);
    // Try this calculation
    const mouseLeft = e.clientX - this.divProgressMeterFull.offsetLeft - this.divPlayerContainer.offsetLeft;
    const s = Math.round((mouseLeft / w) * this.calculatedDuration);
    this.videoElement.currentTime = s;
    this.currentTime = s;

    console.log('the current time after click is set to: ', s);
    console.log('The total time of the video is: ', this.calculatedDuration);

    console.log('the pixels per second = ', w / this.calculatedDuration);
    console.log(' ');

    // drop the bump of the current tag, clear the interval, clear active tag, reset the active index
    if (this.markupIndexActive >= 0) {
      const currTagId = 'imgTag' + this.markupIndexActive;
      document.getElementById(currTagId).classList.remove('grow-foo');
      document.getElementById(currTagId).classList.remove('grow-foo-' + this.markupArray[this.markupIndexActive].markColor);
      clearInterval(this.intervalId);  // does this barf if no current interval ?
      this.activeItem = {};
      this.markupIndexActive = this.whatIsCurrentIndex();
    }

  }

  seekVolume(e: any) {
    console.log('start of seekVolume and the volume is :', this.volume);
    console.log('the event is: ', e);
    console.log('the event target is: ', e.value);
    // console.log('e.target.value is :', e.target.value);
    // this.volume = e.target.value;
    this.volume = e.value;
    this.videoElement.volume = this.volume / 10;
    console.log('end of seekVolume and the volume is :', this.volume);
  }

  rewind(ammount: number) {

    // console.log('in the Rewind, ammount is: ' + ammount + ' and the current time is: ' + this.currentTime + ' and the total time is: ' + this.totalTime);
    if (this.currentTime <= ammount) {
      this.videoElement.currentTime = 0;
    } else {
      this.videoElement.currentTime = this.currentTime - ammount;
    }
  }

  fastforward(ammount: number) {

    if ((this.currentTime + ammount) >= this.totalTime) {
      this.videoElement.currentTime = this.totalTime - 0.2;
    } else {
      this.videoElement.currentTime = this.currentTime + ammount;
    }
  }

  dragStart = function (e: any) {
    this.isDragging = true;

  };



  dragMove = function (e: any) {
    if (this.isDragging) {
      // console.log("in the dragMove evant handler and the e.clientX is :", e.clientX);
      const mouseLeft = e.clientX - this.divProgressMeterFull.offsetLeft - this.divPlayerContainer.offsetLeft;

      if (mouseLeft >= 0) {
        this.calculatedWidth = mouseLeft;
      } else {
        this.calculatedWidth = 0;
      }
      // console.log("in the dragMove evant handler and the the width is :", this.calculatedWidth);
    }
  };

  dragStop = function (e: any) {
    console.log('drag ended - e is: ', e);
    console.log('the isDragging value is: ', this.isDragging);
    if (this.isDragging) {
      this.isDragging = false;
      this.seekVideo(e);
    }
  };

  videoEnded = (e: any) => {
    this.isAtStartOrEnd = true;
    this.isPlaying = false;
  }

  updateTime = (e: any) => {
    this.currentTime = this.videoElement.currentTime;
    this.timerFired();
  }

  whatIsCurrentIndex(): number {
    let foundItemIndex = -1;
    for (let _i = 0; _i < this.markupArray.length; _i++) {
      const markupItem = this.markupArray[_i];
      if (markupItem.markStart <= this.currentTime) {
        foundItemIndex = _i;
      }
    }
    return foundItemIndex;
  }

  timerFired = () => {
    // console.log("in the Timer Fired routine and the markup array is :", this.markupArray);
    if (!this.isDragging) {
      this.calculatedScrubY = this.videoElement.offsetHeight;
      // this.divPlayerContainerHeight
      //     = this.divPlayerContainer.clientHeight - document.getElementById("scrolling-list-header").clientHeight -16;
      const t = this.videoElement.currentTime;
      // let d = this.videoElement.duration;
      // this.calculatedWidth = (t/d* this.videoElement.offsetWidth);

      this.calculatedTotalWidth = this.videoElement.offsetWidth;
      this.calculatedDuration = this.videoElement.duration;
      this.calculatedWidth = ((t / this.calculatedDuration) * this.calculatedTotalWidth);

      // check to see what index into the tag array we are on
      const foundItemIndex = this.whatIsCurrentIndex();

      if ((foundItemIndex !== this.markupIndexActive) || this.tagWasClickedDirectly) {
        this.tagWasClickedDirectly = false;
        this.markupIndexActive = foundItemIndex;
        // pause the video.
        this.videoElement.pause();
        this.isPlaying = false;
        // close all the previous grow bumps
        for (let _j = 0; _j < this.markupIndexActive; _j++) {
          const oldTagId = 'imgTag' + (_j);
          document.getElementById(oldTagId).classList.remove('grow-foo');
          document.getElementById(oldTagId).classList.remove('grow-foo-' + this.markupArray[_j].markColor);
        }

        // try activating the grow class on the imgTag at this index.
        // NEED TO MAKE THIS A COMPONENT AND PUT THIS IN THE COMPONENT LOGIC
        if (this.markupIndexActive >= 0) {
          // this.howManyTimes2++;
          // console.log("in the 'timer Fired' does this get called a lot : " + this.howManyTimes2 + " times");

          // set the tag grafic
          const newTagId = 'imgTag' + this.markupIndexActive;
          document.getElementById(newTagId).classList.add('grow-foo');
          document.getElementById(newTagId).classList.add('grow-foo-' + this.markupArray[this.markupIndexActive].markColor);

          // let newTagElement = document.getElementById(newTagId);
          // console.log("in timer fired AFTER and the new tag element is :", newTagElement);
          // console.log("");

          // set the active tag; will show on video; set the timer.
          this.activeItem = this.markupArray[this.markupIndexActive];
          if (this.activeItem.voiceNoteUrl !== '') {
            this.audioPlayerTagElement.src = this.activeItem.voiceNoteUrl;
            this.audioPlayerTagElement.load();
          }
          // does this barf if no current interval ?
          // if (this.intervalId) {
          clearInterval(this.intervalId);
          // }
          this.intervalCount = this.intervalLength;
          this.playbackResumesIn = this.intervalCount - (this.intervalLength - this.pauseToReadLength);
          // this.intervalId = setInterval(this.countdownTimer, 1000);
          if (this.previouslyPlaying) {
            this.intervalId = setInterval(() => { this.countdownTimer(); }, 1000);
          }

          // set the active tag on the scroll element - this is done with a call back on the div.
          if (!this.videoElement.paused) {
            let newScrollTagId: any;
            if (this.markupIndexActive < this.markupArray.length - 2) {
              newScrollTagId = 'scrollTag' + (this.markupIndexActive + 2);
            } else if (this.markupIndexActive < this.markupArray.length - 1) {
              newScrollTagId = 'scrollTag' + (this.markupIndexActive + 1);
            } else {
              newScrollTagId = 'scrollTag' + (this.markupIndexActive);
            }
            // figure out a way for smoother scrolling
            // and only scroll if the highlighted dive is out of the visible area.
            document.getElementById(newScrollTagId).scrollIntoView(false);
          }
        }
      }
    }
  }

  countdownTimer() {
    console.log('In countdown Timer routine');
    this.intervalCount--;
    this.playbackResumesIn = this.intervalCount - (this.intervalLength - this.pauseToReadLength);
    if (this.intervalCount <= (this.intervalLength - this.pauseToReadLength)) {
      // start playing the video
      this.videoElement.play();
      this.isPlaying = true;
      this.previouslyPlaying = true;
      this.isAtStartOrEnd = false;
    }

    if (this.intervalCount <= 0) {
      // clear the active item
      this.activeItem = {};
      clearInterval(this.intervalId);
      // drop the image tag as well.
      const currTagId = 'imgTag' + this.markupIndexActive;
      document.getElementById(currTagId).classList.remove('grow-foo');
      document.getElementById(currTagId).classList.remove('grow-foo-' + this.markupArray[this.markupIndexActive].markColor);

    }
  }

  muteVideo() {
    if (this.videoElement.volume === 0) {
      this.videoElement.volume = 1;
      this.isMuted = false;
    } else {
      this.videoElement.volume = 0;
      this.isMuted = true;
    }
  }

  playVideo() {
    if (this.videoElement.paused) {
      this.videoElement.play();
      this.isPlaying = true;
      this.previouslyPlaying = true;
      this.isAtStartOrEnd = false;
      if (this.intervalCount > 0) {
        clearInterval(this.intervalId);
        this.intervalId = setInterval(() => { this.countdownTimer(); }, 1000);
      }
    } else {
      this.videoElement.pause();
      this.isPlaying = false;
      this.previouslyPlaying = false;
      clearInterval(this.intervalId);
    }
  }

  // PE tag stuff
  setATag() {
    console.log('In the set a Tag function');

    // pause and close other stuff
    this.videoElement.pause();
    this.isPlaying = false;
    if (this.intervalCount > 0) {
      clearInterval(this.intervalId);
    }
    this.isAtStartOrEnd = false;
    this.activeItem = {};
    if (this.markupIndexActive >= 0) {
      const currTagId = 'imgTag' + this.markupIndexActive;
      document.getElementById(currTagId).classList.remove('grow-foo');
      document.getElementById(currTagId).classList.remove('grow-foo-' + this.markupArray[this.markupIndexActive].markColor);
    }

    // let t = this.videoElement.currentTime;
    // this.calculatedTotalWidth = this.videoElement.offsetWidth;
    // this.calculatedDuration = this.videoElement.duration;
    // let local = ((t / this.calculatedDuration) * this.calculatedTotalWidth);
    this.currentTime = this.videoElement.currentTime;

    this.newItemEntry.markStart = this.currentTime;
    this.newItemEntry.customText = '';
    this.newItemEntry.uid = this.afs.createId();
    this.showNewTagEntryScreen = true;


    // get the values from the form


    // this.markupArray.splice( timeIndex+1, 0, {
    //             "markColor": "red",
    //             "markStart": this.currentTime,
    //             "markType": "Time on Task",
    //             "markText": "I ADDED THIS ONE BUT WILL NEED TO SET THE TIME BETTER"
    //         });
  }

  onRadioTagTypeSelection(n: number) {
    // reset the markText to ""
    this.newItemEntry.markText = null;
    // set the color
    this.newItemEntry.markColor = this.theTagDataValuesArray[n].sectionColor;
  }

  async newTagEntrySubmitted() {
    const timeIndex = this.whatIsCurrentIndex();

    if ((this.newItemEntry.markText || this.newItemEntry.customText) && this.newItemEntry.markType) {

      // set custom text if entered
      if (this.newItemEntry.customText !== '') {
        this.newItemEntry.markText = this.newItemEntry.customText;
        // need to check for the case if no radio button is checked beacause customText was all deleted.
      }
      delete this.newItemEntry.customText;
      this.newItemEntry.evaluationId = this.evaluationId;

      console.log('this is the tag as it is being added ! from videoService : ');


      // Call the server and add the tag
      await this.videoMarkupService.addVideoMarkupTagFromJSON(this.newItemEntry)
        .then(resultUid => {
          console.log('in the Video Service addVideoMarkupTag observable  - result is :', resultUid);
          this.newItemEntry.uid = resultUid;
          // Set it here after doing the proper fix up below.

          // update the eval that the PDF needs regenerated.
          const smallJSON: any = {};
          smallJSON.PDFNeedsRegenerated = true;
          this.evalService.updateEvaluation(this.evaluationId, smallJSON);
        })
        .catch(error => {
          console.log('in the Video Service newTagEntrySubmitted an error occurred adding tag to server : ', error);
          // this.status = null;
          // this.error = error.json();
          // this.loading = false;
        });

      // Set a spinner here to wait until it is added to the Server and then come back here and set all of this up
      // in the "result" code segment.
      // this.markupArray.splice(timeIndex + 1, 0, this.newItemEntry);  /// they might be getting added twice from the observable to the table. Or this just can go away.
      this.showNewTagEntryScreen = false;
      this.newItemEntry = {};
      this.newTagEntryErrorMessage = null;

      this.currentTime = this.markupArray[timeIndex + 1].markStart;
      this.videoElement.currentTime = this.currentTime;

    } else {
      this.newTagEntryErrorMessage = 'A tag type and a value must be selected or entered';
    }
  }

  newTagEntryCancelled() {

    this.showNewTagEntryScreen = false;
    this.newItemEntry = {};
    this.newTagEntryErrorMessage = null;

  }

  changeRadioTextField() {

  }

  tagWasClicked(e: any) {
    if (this.markupIndexActive >= 0) {
      const currTagId = 'imgTag' + this.markupIndexActive;
      document.getElementById(currTagId).classList.remove('grow-foo');
      document.getElementById(currTagId).classList.remove('grow-foo-' + this.markupArray[this.markupIndexActive].markColor);
    }


    console.log('In the Tag was clicked function, event is = ', e);
    console.log('The tag element is', document.getElementById(e.target.id));
    const arrayIndex = Number(e.target.id.replace('imgTag', ''));
    console.log('array index is : ', arrayIndex);
    console.log('the tag array details are : ', this.markupArray[arrayIndex]);

    this.currentTime = this.markupArray[arrayIndex].markStart;
    // This will cause the time change alert. So the tag is highlighted unless it is already the active one
    // in which case we should treat it as new with a state variable.
    this.tagWasClickedDirectly = true;
    this.videoElement.currentTime = this.currentTime;



    // this.setScrollingArray();

    /// ***** There is something that I need to fix when clicking on a Tag that is just behind the scrubber
    /// this is not activating the tag properly ... also need to do a better job of not
    /// starting the delay timer whenever a tag is clicked.
  }

  deleteActiveTag() {
    console.log('in the video service deleteActiveTag routine and the current tag is: ', this.activeItem);

    if (this.activeItem.uid) {
      console.log('This is an item that can be deleted');
      this.markupIndexToDelete = this.markupIndexActive;

      this.videoMarkupService.deleteVideoMarkupTag(this.activeItem.uid)
        .then(deleteResult => {
          // Nothing will be returned here.
          console.log('in the Video Service deleteActiveTag observable  - good result is :', deleteResult);
          // remove the tag from the array & reset the delete marker
          if (this.markupIndexActive === this.markupIndexToDelete) {
            this.activeItem = {};
            clearInterval(this.intervalId);
          }
          // this.markupArray.splice(this.markupIndexToDelete, 1);  // THIS WILL BE A PROBLEM -- TAGs WILL CAUSE DOUBLE DEletes !!
          this.markupIndexToDelete = -1;
          this.timerFired();

          // update the eval that the PDF needs regenerated.
          const smallJSON: any = {};
          smallJSON.PDFNeedsRegenerated = true;
          this.evalService.updateEvaluation(this.evaluationId, smallJSON);

        })
        .catch(error => {
          console.log('in the Video Service deleteActiveTag an error occurred deleting tag from server : ', error);
          this.markupIndexToDelete = -1;
          // this.status = null;
          // this.error = error.json();
          // this.loading = false;
        });

    } else {
      console.log('This item does not have the DB id and can\'t be deleted - FIX THIS');
    }
  }

  // grabCurrentFrameCVF() {


  //   console.log('Trying to do a frame grab with CVF');

  //   const frame = CVF(this.videoElement, 'png');

  //   console.log('Trying to do a frame grab - after the frame grab');
  //   const img = new Image();
  //   img.src = frame.dataUri;


  //   console.log('Trying to do a frame grab - after the dataURI');
  //   this.grabbedFrame = img.src;
  //   console.log('grabbed the frame and it is - ', this.grabbedFrame);
  // }

  // grabCurrentFrame() {
  //   // Probably need to do this on the server to not get CORS errors.
  //   // ONLY WORKS WHEN VIDEO IS ON THE BROWSER.
  //   console.log('Trying to do a frame grab');

  //   const canvas = document.createElement('canvas');
  //   console.log('Trying to do a frame grab - after creating the canvas');
  //   canvas.height = this.videoElement.videoHeight;
  //   canvas.width = this.videoElement.videoWidth;
  //   const ctx = canvas.getContext('2d');
  //   console.log('Trying to do a frame grab - after getting the context');
  //   ctx.drawImage(this.videoElement, 0, 0, canvas.width, canvas.height);
  //   console.log('Trying to do a frame grab - after drawing the image');
  //   const img = new Image();
  //   img.src = canvas.toDataURL();


  //   console.log('Trying to do a frame grab - after the toDataURL');
  //   this.grabbedFrame = img.src;
  //   console.log('grabbed the frame and it is - ', this.grabbedFrame);
  // }

  // grabCurrentFrameRemote() {
  //   // This does not grab the Video frame :-(
  //   console.log('Trying to do a frame grab');
  //   try {
  //     const canvas = document.createElement('canvas');

  //     canvas.height = this.videoElement.videoHeight;
  //     canvas.width = this.videoElement.videoWidth;
  //     const ctx = canvas.getContext('2d');

  //     ctx.drawImage(this.videoElement, 0, 0, canvas.width, canvas.height);
  //     this.videoElement.style.backgroundImage = `url(${canvas.toDataURL()})`; // here is the magic
  //     this.videoElement.style.backgroundSize = 'cover';
  //     ctx.clearRect(0, 0, canvas.width, canvas.height); // clean the canvas

  // } catch (e) {
  //     console.log('error in the background image part', e);
  // }

  //   html2canvas(this.divFullPlayer, {'allowTaint': true, 'foreignObjectRendering': false})
  //   .then((theCanvas) => {
  //     const img = new Image();
  //     img.src = theCanvas.toDataURL();
  //     console.log('Trying to do a frame grab - after the toDataURL');
  //     this.grabbedFrame = img.src;
  //     console.log('grabbed the frame and it is - ', this.grabbedFrame);

  //   console.log('Trying to do a frame grab - after the toDataURL');
  //   this.grabbedFrame = img.src;
  //   })
  //   .catch((error) => {
  //     console.log('There was an error in the html2canvas :', error);
  //   });

  // }

  // rowClicked(e:any) {
  //     console.log("In the Row Clicked function, event is = ", e);

  // }

  // cellItemClicked(e:any) {
  //     console.log("In the Cell Item Clicked function, event is = ", e);

  // }

  // isThisTheActiveIndex(ti:number) {
  //     // if (ti == this.markupArray[this.markupIndexActive].markStart) {
  //     //this.howManyTimes1++;
  //     //console.log("in the 'isThisTheActiveIndex routine' does this get called a lot : " + this.howManyTimes1 + " times");
  //     if (ti == this.markupIndexActive) {
  //         // is the active index
  //         return "lightblue";
  //         // this is the same as "True""
  //     } else {
  //         return "";
  //         // this is the same as "False"
  //     }
  // }


  // clickedOnRow(curIndex) {
  //     console.log("clicked on a Row and the playerContainer height is", this.divPlayerContainer.clientHeight);

  //     // drop the current tag
  //     if (this.markupIndexActive >= 0) {
  //         let currTagId = 'imgTag' + this.markupIndexActive;
  //         document.getElementById(currTagId).classList.remove("grow-foo");
  //         document.getElementById(currTagId).classList.remove('grow-foo-' + this.markupArray[this.markupIndexActive].markColor);
  //    }


  //     // let timeIndex = -1;
  //     // for (let _i = 0; _i < this.markupArray.length; _i++) {
  //     //     let markupItem = this.markupArray[_i];
  //     //     if (timeIndex = curIndex)
  //     //     if (markupItem.markStart <= curItem.markStart) {
  //     //         timeIndex = _i;
  //     //     }
  //     // }


  //     this.currentTime = this.markupArray[curIndex].markStart;
  //     this.videoElement.currentTime = this.currentTime;

  //     // may need to call timerFired here ...
  // }

}

