import { Component, OnInit } from '@angular/core';
import { GlobalUtilitiesService } from './utils/global-utils.service';
import { Title } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(
    public gu: GlobalUtilitiesService,
    private titleService: Title,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'forward_1-24px',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/forward_1-24px.svg')
    );
  }


  ngOnInit() {
    this.titleService.setTitle( 'Practice Evaluation' );
  }
}
