import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';

import { AngularFirestore } from 'angularfire2/firestore';
import { Observable } from 'rxjs/Observable';

import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { EvaluationModel } from '../models/evaluation-model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { EvaluationsService } from '../evaluations-service/evaluations-service.service';
import { UserMgmtService } from '../user-mgmt-service/user-mgmt-service.service';
import { AuthService } from '../auth/auth.service';

import * as _ from 'lodash';
import { EvaluatorModel } from '../models/evaluator-model';
import { EvaluatorsListService } from '../evaluators-list-service/evaluators-list-service.service';
import { GlobalUtilitiesService } from '../utils/global-utils.service';

@Component({
  selector: 'app-evaluations-list-page',
  templateUrl: './evaluations-list-page.component.html',
  styleUrls: ['./evaluations-list-page.component.scss']
})
export class EvaluationsListPage implements OnInit, AfterViewInit {

  listSubscription;

  displayedColumns = ['lastName', 'email', 'statusKey', 'dateCreated', 'evaluator', 'edit'];
  dataSource: MatTableDataSource<any>;

  evaluationType: string;

  theEvaluatorsList$: Observable<EvaluatorModel[]>;
  theEvaluatorsList: EvaluatorModel[];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    public theRoute: ActivatedRoute,
    private evalService: EvaluationsService,
    private evaluatorsListService: EvaluatorsListService,
    private userMgmtService: UserMgmtService,
    public authService: AuthService,
    public gu: GlobalUtilitiesService
  ) { }

  statusValue(key: number): string {
    return this.gu.statusValueForKey(key);
  }

  ngOnInit() {
    this.theRoute.params
      .map((params: Params) => params)
      .subscribe(params => {
        console.log('is this getting called ?? - params are: ', params);
        this.evaluationType = params.listType;
        console.log('is this getting called ?? - listType is: ', this.evaluationType);
        if (this.listSubscription) {
          this.listSubscription.unsubscribe();
        }
        this.InitializeCode();
      });
  }

  ngAfterViewInit() {
    this.InitializeCode();
  }

  InitializeCode() {

    this.listSubscription = this.evalService.getAllEvaluations(this.evaluationType).subscribe(evalsData => {
      this.dataSource = new MatTableDataSource(evalsData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      // This is where I do my custome sorting !!
      this.dataSource.sortingDataAccessor = (item, property) => {

        // console.log('In the sorting Data Accessor - when does this happen ??')
        // console.log('What is the item ??: ', item);
        // console.log('What is the property ??: ', property);
        // return item[property];

        switch (property) {

          case 'evaluator': return item.evaluatorAssignedUsername;
          default: return item[property];
        }
      };
    });

    this.theEvaluatorsList$ = this.evaluatorsListService.getEvaluatorsList();
    this.theEvaluatorsList$.subscribe(usersList => {
      console.log('in the Evals list after view Init and the users are: ', usersList);
      this.theEvaluatorsList = usersList;
    });

  }

  openDetailsPage(theDocId: string) {
    this.router.navigate(['/evaluation/' + theDocId]);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  trackByUid(index, item) {
    return item.uid;
  }

  assignedEvaluatorChanged(theEventValue, theEvaluation: EvaluationModel) {
    console.log('in assignedEvaluatorChanged and the event is: ', theEventValue);
    console.log('in assignedEvaluatorChanged and the Evaluation is: ', theEvaluation);

    const smallJSON: any = {};
    if (theEventValue === '') {
      smallJSON.evaluatorAssignedUid = '';
      smallJSON.evaluatorAssignedUsername = '';
      smallJSON.dateAssigned = new Date('1-1-1970');
      smallJSON.PDFNeedsRegenerated = true;
      if (theEvaluation.statusKey === 5) {
        smallJSON.statusKey = 4;
      }
    } else {
      // tslint:disable-next-line:triple-equals
      const evalorIndex = _.findIndex(this.theEvaluatorsList, function (o) { return o.uid == theEventValue; });
      console.log('The selected evaluator is: ', this.theEvaluatorsList[evalorIndex]);
      smallJSON.evaluatorAssignedUid = theEventValue;
      smallJSON.evaluatorAssignedUsername = this.theEvaluatorsList[evalorIndex].name;
      smallJSON.dateAssigned = new Date();
      smallJSON.PDFNeedsRegenerated = true;
      if (theEvaluation.statusKey === 4) {
        smallJSON.statusKey = 5;
      }
    }

    this.evalService.updateEvaluation(theEvaluation.uid, smallJSON);

  }
}
