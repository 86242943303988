import { Component, OnInit, AfterViewInit, HostListener, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
// import { Router, ActivatedRoute, Route } from '@angular/router';
import { VideoService } from '../video-service/video-service.service';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../utils/storage-service/storage.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'video-tagger-three',
  templateUrl: './video-tagger-three.component.html',
  styleUrls: ['./video-tagger-three.component.scss']
})
export class VideoTaggerThreeComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() public videoService: VideoService;
  @Input() public theVideoUrl: string;
  @Input() public theEvaluationId: string;

  @Output() infoCallbackEvent = new EventEmitter<{}>();

  // @ViewChild('voice-recording') oldPlayer: ElementRef;

  // evaluationId: String;
  activeMouseover: boolean;

  constructor(
     public authService: AuthService, // for testing remove later.
     public storageService: StorageService, // for testing remove later.

  ) { }

  ngOnInit() {
    // this.videoService.evaluationId = this.route.snapshot.params['id'];
    this.videoService.evaluationId = this.theEvaluationId;
    this.videoService.appSetup('videoDisplay', this.theVideoUrl);

    console.log('in the ngOnInit and the evaluationId for the videoService is : ', this.videoService.evaluationId);

    console.log('in the ngOnIint and the passed in videoURL is: ', this.theVideoUrl);
    console.log('in the ngOnIint and the passed in evaluationId is: ', this.theEvaluationId);
    console.log('sending back a simple object through the event emitter');


  }

  ngAfterViewInit() {
    // this needs to fire after a delay or get signaled by the VideoService.
    this.infoCallbackEvent.emit({
      'length': this.videoService.totalTime,
      'numberOfTags': this.videoService.markupArray.length
    });
  }

  // Update the layout calcualtions on resize event when the video is paused
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // console.log("Host Listener windowResize callback has fired on VideoTaggerThreeComponent - call video service to update sizes");
    // console.log("The resize event is : ", event );
    this.videoService.timerFired();

  }

  ngOnDestroy() {
    console.log(' ');
    console.log('ngOnDestroy called for Video Tagger !!!!!!!! set the videoService time back to 0');
    console.log(' ');
    this.videoService.totalTime = 0;
  }

  justForTesting() {
    this.storageService.generateFrameGrabs(this.theEvaluationId, this.theVideoUrl, [1, 2]);
    this.storageService.getVideoMetaData(this.theVideoUrl);
  }

}
