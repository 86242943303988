// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

    firebaseConfig: {
      apiKey: 'AIzaSyA65k0l76W_5-vQu4YkTm1e91-ZCNpsAeo',
      authDomain: 'practice-evaluation-3.firebaseapp.com',
      databaseURL: 'https://practice-evaluation-3.firebaseio.com',
      projectId: 'practice-evaluation-3',
      storageBucket: 'practice-evaluation-3.appspot.com',
      messagingSenderId: '492558620270'
    },

    functionsURL: 'https://us-central1-practice-evaluation-3.cloudfunctions.net',
    stripePublishable: 'pk_test_gVlVArZA5RDh4U7XW7OyBoJi'
};
