import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, QueryDocumentSnapshot } from 'angularfire2/firestore';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MarkupTagTextValuesService {

  constructor(private afs: AngularFirestore, private http: HttpClient) { }

  getTagDataValues(tagSet: string): Observable<any[]> {

    const AgencyDataCollection: AngularFirestoreCollection<any> = this.afs.collection('TagTextValues/' + tagSet + '/Sections'); // reference

    return AgencyDataCollection.valueChanges().pipe(map(items => {
      console.log('Value Changes has returned in the list of raw tag text values(from service) !!', items);
      return _.sortBy(items, 'sectionIndex');
      // return tempItems.map(itemd => {

      //   return itemd;
      // });
    }));
  }






  async loadTagDataFromAFile(theFileName: string, forTagSet: string) {
    // const dataFileName = 'TagTextValuesCore.json';

    // first delete the existing values.
    // const deleteArray: string[] = [];
    // deleteArray.push('TagTextValues/' + forTagSet);
    const deleteResult = await this.deleteCollections('TagTextValues/' + forTagSet + '/Sections');
    console.log('Deletion of existing tags is complete and the result was: ', deleteResult);

    const fileResults = await this.http.get('./assets/datafiles/' + theFileName).toPromise();

    const fields: any = fileResults;

    for (const sectionData of fields) {
      console.log('the Section to Add is: ');
      console.log(sectionData);

      const AgencyDataCollection: AngularFirestoreCollection<any> = this.afs.collection('TagTextValues/' + forTagSet + '/Sections'); // reference
      const singleResult = await AgencyDataCollection.doc(sectionData.sectionName).set({ ...sectionData });

      console.log('The result from adding the section data was: ', singleResult);
    }
  }












  //  Copied this Generic deletion routine.
  /**
 * Delete all documents in specified collections.
 *
 * @param {string} collections Collection names
 * @return {Promise<number>} Total number of documents deleted (from all collections)
 */
  async deleteCollections(...collections: string[]) {
    let totalDeleteCount = 0;
    const batchSize = 500;
    return new Promise<number>((resolve, reject) => Observable
      .from(collections)
      .concatMap(collection => Observable.fromPromise(this.afs.collection(collection).ref.get()))
      .concatMap(q => Observable.from(q.docs))
      .bufferCount(batchSize)
      .concatMap((docs: QueryDocumentSnapshot<any>[]) => Observable.create((o: Observer<number>) => {
        const batch = this.afs.firestore.batch();
        docs.forEach(doc => batch.delete(doc.ref));
        batch.commit()
          .then(() => {
            o.next(docs.length);
            o.complete();
          })
          .catch(e => o.error(e));
      })
      )
      .subscribe(
        (batchDeleteCount: number) => totalDeleteCount += batchDeleteCount,
        e => reject(e),
        () => resolve(totalDeleteCount)
      )
    );
  }
}
