
export class UserData {
    public uid = ''; // set by FireBase db record id... if blank then not in DB.
    public existsInFirebase = false;
    public userFirstName = '';
    public userLastName = '';

    public userPhotoURL = '';

    // Address block
    public userDisplayName = '';

    public userEmail = '';
    public userPhone = '';

    public loginProvider = ''; // this is where the user came from as an OAuth2 provider like Google, Facebook, Email/Password, etc.

    public createdBy = ''; // userId :
    public dateCreated: Date;

    // public roles: Roles;

    constructor(

    ) { }

    get UserFullName(): string {
        // console.log("IS THIS EVEN GETTING CALLED");
        return this.userFirstName + ' ' + this.userLastName;
    }

    set UserFullName(val: string) {
        // Parse the name accordingly
        console.log('That values is: ', val);
    }

    public fromJSON(obj: any) {
        // console.log('in the from JSON of the UserData and the obj is: ', obj);
        for (const key in obj) {
            if (this.hasOwnProperty(key)) {

                this[key] = obj[key];

            }
        }
    }

}
