import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { VoucherCodeModel } from '../models/voucher-code-model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VoucherCodeService {

  // Nov 11, 2018 - Voucher Codes are assigned to a specific user and can be valid for upt to 365 days.

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private http: HttpClient
  ) { }

  async adminAddUnusedVoucherCodesToMasterTable() {


    return new Promise(async (resolve, reject) => {

      if (this.authService.hasSysAdminPermissions()) {
        try {

          const fileResults = await this.http.get('./assets/datafiles/' + 'VoucherCodeImport.json').toPromise();

          const fields: any = fileResults;

          console.log('Read in the file and the data is', fields);

          for (const singleCode of fields) {
            // console.log('the voucher code to Add is: ', singleCode.voucherCode);

            const tempVoucher = new VoucherCodeModel();

            tempVoucher.fromJSON(singleCode);

            console.log('the full object is :', tempVoucher);

            // const voucherCodeCollection: AngularFirestoreCollection<any> = this.afs.collection('Vouchers/Base/VoucherCodes'); // reference
            // const singleResult = await voucherCodeCollection.add({...tempVoucher});
            const voucherCodeCollection: AngularFirestoreCollection<any> = this.afs.collection('Vouchers/Base/VoucherCodes'); // reference
            const singleResult = await voucherCodeCollection.doc(tempVoucher.voucherCode).set({ ...tempVoucher });

            // console.log('The result from adding the section data was: ', singleResult);
          }

          const voucherStatData: AngularFirestoreDocument<any> = this.afs.doc('Vouchers/Base');
          const currentBaseDataRaw = await voucherStatData.ref.get();
          const currentBaseData: any = currentBaseDataRaw.data();
          console.log('The previous Voucher Base Data is: ', currentBaseData);
          console.log('The fields count is: ', fields.length);
          const newAvailable = currentBaseData.numberAvailable + fields.length;
          const newAllocated = currentBaseData.totalAllocated + fields.length;
          console.log('new Available is: ', newAvailable);
          console.log('new Allocated is: ', newAllocated);
          const baseStatsUpdateResult = await voucherStatData.update({ 'numberAvailable': newAvailable, 'totalAllocated': newAllocated });

          resolve({
            'status': 'SUCCESS',
            'message': fields.length + ' new voucher codes were loaded successfully'
          });



        } catch (error) {
          console.log('In the error portion after the call to get the voucher code file and the error is: ', error);
          reject({
            'status': 'ERROR FILE ERROR',
            'error': error
          });
        }

      } else {
        reject({
          'status': 'ERROR UNAUTHORIZED',
          'error': 'This is only avalable to system admins.'
        });
      }

    });
  }

  adminGetBaseData(): Observable<any> {

    if (this.authService.hasSysAdminPermissions()) {
      const voucherStatData: AngularFirestoreDocument<any> = this.afs.doc('Vouchers/Base');
      return voucherStatData.valueChanges();

    } else {
      console.log('Trying to get base Data when NOT Admin - this should not happen');
      return null;
    }

  }

  adminAssignVoucherCodeToEmail(theEmail: string, daysValidFor: number, numberOfUsesAllowed: number, assignedByUid: string, assignedByName: string): Promise<any> {


    const tempURL = 'https://us-central1-practice-evaluation-3.cloudfunctions.net/assignNextVoucherCode';

    const httpBody: any = {};
    httpBody.assignedByUid = assignedByUid;
    httpBody.assignedByName = assignedByName;
    httpBody.daysValidFor = daysValidFor;
    httpBody.numberOfUsesAllowed = numberOfUsesAllowed;
    httpBody.email = theEmail;

    return new Promise((resolve, reject) => {

      if (this.authService.hasSysAdminPermissions()) {

        this.http
          .post(tempURL, httpBody)
          .subscribe(
            data => {
              const resp: any = data;
              console.log('In the Voucher Service after the HTTP call to assign the Coupion and the tempResponse is: ', resp);

              if (resp.result === 'SUCCESS') {


                resolve({
                  'status': 'SUCCESS',
                  'voucherCode': resp.voucherCode,
                  'expirationDate': resp.expirationDate,
                  'emailOfUser': resp.emailOfUser,
                  'numberOfUsesAllowed': resp.numberOfUsesAllowed,
                });

              } else {
                reject({
                  'status': 'ERROR',
                  'error': resp.message,
                });
              }

            },
            error => {
              console.log('In the error portion after the call to the backend to assign the next voucher code and the error is: ', error);
              reject({
                'status': 'ERROR',
                'error': error,
              });
            }
          );

      } else {

        reject({
          'status': 'ERROR',
          'error': 'This is ONLY avalable to system admins.'
        });
      }
    });

    // return new Promise(async (resolve, reject) => {

    //   resolve({'result': 'SUCCESS'});
    // });

  }

  adminSeeVoucherCodeUsage(theCode: string): Promise<VoucherCodeModel> {

    return new Promise(async (resolve, reject) => {

      const tempVoucher = new VoucherCodeModel();
      resolve(tempVoucher);
    });
  }

  adminDisableVoucherCode(thCode: string, theReason: string) {


  }

  checkVoucherCodeValidity(theCode: string, theEmail: string): Promise<any> {

    const tempURL = 'https://us-central1-practice-evaluation-3.cloudfunctions.net/verifyVoucherCode';

    // this should be an http.get with the email and code as parameters at some point.
    const httpBody: any = {};
    httpBody.voucherCode = theCode;
    httpBody.email = theEmail;

    return new Promise((resolve, reject) => {

      this.http
        .post(tempURL, httpBody)
        .subscribe(
          data => {
            const resp: any = data;
            console.log('In the Voucher Service after the HTTP call to verify the Voucher and the tempResponse is: ', resp);

            if (resp.result === 'VALID') {
              resolve({
                'result': 'VALID',
                'message': resp.message
              });

            } else if (resp.result === 'NOT VALID') {
              resolve({
                'result': 'NOT VALID',
                'reason': resp.reason
              });
            } else {
              reject({
                'result': 'ERROR',
                'error': resp.message,
              });
            }

          },
          error => {
            console.log('In the error portion after the call to the backend to check the voucher validity and the error is: ', error);
            reject({
              'result': 'ERROR',
              'error': error,
            });
          }
        );
    });

  }

  requestToUseVoucherCode(theCode: string, theEmail: string, evalId: string) {

    const tempURL = 'https://us-central1-practice-evaluation-3.cloudfunctions.net/useVoucherCode';

    // this should be an http.get with the email and code as parameters at some point.
    const httpBody: any = {};
    httpBody.voucherCode = theCode;
    httpBody.email = theEmail;
    httpBody.evalId = evalId;

    return new Promise((resolve, reject) => {

      this.http
        .post(tempURL, httpBody)
        .subscribe(
          data => {
            const resp: any = data;
            console.log('In the Voucher Service after the HTTP call to use the Voucher and the tempResponse is: ', resp);

            if (resp.result === 'VALID') {
              resolve({
                'result': 'VALID',
                'message': resp.message,
                'expirationDate': resp.expirationDate,
                'isPaid': resp.isPaid,
                'ammountPaid': resp.ammountPaid
              });

            } else if (resp.result === 'NOT VALID') {
              resolve({
                'result': 'NOT VALID',
                'reason': resp.reason
              });
            } else {
              reject({
                'result': 'ERROR',
                'error': resp.message,
              });
            }

          },
          error => {
            console.log('In the error portion after the call to the backend to actually use the voucher code and the error is: ', error);
            reject({
              'result': 'ERROR',
              'error': error,
            });
          }
        );
    });

  }

  emailVoucherCodeToUser(theUser: string, theCode: string, theEmail: string) {

    const tempURL = 'https://us-central1-practice-evaluation-3.cloudfunctions.net/httpVoucherCodeEmail';

    // this should be an http.get with the email and code as parameters at some point.
    const httpBody: any = {};
    httpBody.toName = theUser;
    httpBody.voucherCode = theCode;
    httpBody.toEmail = theEmail;

    return new Promise((resolve, reject) => {

      this.http
        .post(tempURL, httpBody)
        .subscribe(
          data => {
            const resp: any = data;
            console.log('In the Voucher Service after the HTTP call to use the email the Voucher Code and the tempResponse is: ', resp);

            if (resp.result === 'success') {
              resolve({
                'result': 'success',
                'message': resp.details,
              });

            } else {
              reject({
                'result': 'ERROR',
                'error': resp.details,
              });
            }

          },
          error => {
            console.log('In the error portion after the call to the backend to send the voucher in email and the error is: ', error);
            reject({
              'result': 'ERROR',
              'error': error,
            });
          }
        );
    });

  }

}
