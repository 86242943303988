
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { ROUTES } from './app.routes';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthService } from './auth/auth.service';
import { SysAdminGuard } from './auth/sysadmin.guard';
import { RegUserGuard } from './auth/reguser.guard';
import { GlobalUtilitiesService } from './utils/global-utils.service';
import { AlertModule, BsDropdownModule, BsDatepickerModule, ModalModule } from 'ngx-bootstrap';

import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { environment } from '../environments/environment';
export const firebaseConfig = environment.firebaseConfig;

// import { AgGridModule } from 'ag-grid-angular';
import { DataTableModule } from 'angular2-datatable';

import { NavTopComponent } from './nav-top/nav-top.component';
import { NavFooterComponent } from './nav-footer/nav-footer.component';

import { AdminHomePage } from './admin-home-page/admin-home-page.component';
import { UserMgmtDetail } from './user-mgmt-detail/user-mgmt-detail.component';
import { UserMgmtList } from './user-mgmt-list/user-mgmt-list.component';
import { UserMgmtService } from './user-mgmt-service/user-mgmt-service.service';
import { ItemUploadService } from './utils/file-upload/item-upload.service';
import { BlobUploadService } from './utils/file-upload/blob-upload.service';
import { LoadingSpinnerComponent } from './utils/loading-spinner/loading-spinner.component';
import { NotFoundComponent } from './utils/not-found/not-found.component';
import { LoginPage } from './login-page/login-page.component';
import { SiteHomePage } from './site-home-page/site-home-page.component';
import { NewEvaluationFormComponent } from './new-evaluation-form/new-evaluation-form.component';

import { PackageDetailsService } from './package-details-service/package-details-service.service';
import { CdkTableModule } from '@angular/cdk/table';
import { EvaluationsService } from './evaluations-service/evaluations-service.service';
import { SendgridService } from './utils/sendgrid/sendgrid-service.service';
import { PaymentService } from './stripe/payment-service/payment-service.service';


import {
  MatButtonModule,
  MatTableModule,
  MatPaginatorModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatChipsModule,
  MatFormFieldModule,
  MatInputModule,
  MatSortModule,
  MatDatepickerModule,
  MatDatepicker,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatNativeDateModule,
  MatRadioModule,
  MatSelectModule,
  MatSliderModule,
  MatOptionModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSlideToggleModule, ErrorStateMatcher, ShowOnDirtyErrorStateMatcher
} from '@angular/material';


import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EvaluationsListPage } from './evaluations-list-page/evaluations-list-page.component';
import { EvaluationDetailPage } from './evaluation-detail-page/evaluation-detail-page.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { ScrollTimeDisplayPipe } from './pipes/scroll-time-display.pipe';
import { TimeDisplayPipe } from './pipes/time-display.pipe';


import { VideoTaggerThreeComponent } from './video-tagger-three/video-tagger-three.component';
import { VideoService } from './video-service/video-service.service';
import { MarkupTagService } from './markup-tag-service/markup-tag-service.service';
import { MarkupTagTextValuesService } from './markup-tag-text-values-service/markup-tag-text-values-service.service';
import { VideoTagEntryFormComponent } from './video-tag-entry-form/video-tag-entry-form.component';
import { VideoToolbarComponent } from './video-toolbar/video-toolbar.component';
import { VideoProgressComponent } from './video-progress/video-progress.component';
import { VoiceRecordingComponent } from './voice-recording/voice-recording.component';
import { MarkupTagListComponent } from './markup-tag-list/markup-tag-list.component';
import { VideoTimelineComponent } from './video-timeline/video-timeline.component';
import { PaymentFormComponent } from './stripe/payment-form/payment-form.component';

import { NgxStripeModule } from 'ngx-stripe';
import { AdminVoucherCodesComponent } from './admin-voucher-codes/admin-voucher-codes.component';
import { VoucherCodeService } from './voucher-code-service/voucher-code-service.service';
import { StorageService } from './utils/storage-service/storage.service';
import { EvaluatorCommentsReportAreaComponent } from './evaluator-comments-report-area/evaluator-comments-report-area.component';



@NgModule({
  declarations: [
    AppComponent,
    AdminHomePage,
    NavTopComponent,
    NavFooterComponent,
    UserMgmtDetail,
    UserMgmtList,
    LoadingSpinnerComponent,
    NotFoundComponent,
    LoginPage,
    SiteHomePage,
    NewEvaluationFormComponent,
    EvaluationsListPage,
    EvaluationDetailPage,
    FileSizePipe,
    ScrollTimeDisplayPipe,
    TimeDisplayPipe,
    VideoTaggerThreeComponent,
    VideoTagEntryFormComponent,
    VideoToolbarComponent,
    VideoProgressComponent,
    VoiceRecordingComponent,
    MarkupTagListComponent,
    VideoTimelineComponent,
    PaymentFormComponent,
    AdminVoucherCodesComponent,
    EvaluatorCommentsReportAreaComponent
  ],
  imports: [
    NgxStripeModule.forRoot('pk_test_gVlVArZA5RDh4U7XW7OyBoJi'),
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES),
    FormsModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    DataTableModule,
    AngularFontAwesomeModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatChipsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatDatepickerModule,
    MatDividerModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    FlexLayoutModule
  ],
  exports: [
    CdkTableModule,
    MatButtonModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatChipsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatDatepickerModule,
    MatDividerModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ],
  entryComponents: [
    NewEvaluationFormComponent
  ],
  providers: [
    AuthService,
    GlobalUtilitiesService,
    SysAdminGuard,
    RegUserGuard,
    ItemUploadService,
    BlobUploadService,
    SendgridService,
    PaymentService,
    VoucherCodeService,
    StorageService,
    DatePipe,
    ScrollTimeDisplayPipe,
    TimeDisplayPipe,
    UserMgmtService,
    PackageDetailsService,
    VideoService,
    EvaluationsService,
    MarkupTagService,
    MarkupTagTextValuesService,
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
