import { Component, OnInit, ViewChild } from '@angular/core';
import { UserData } from '../models/user-data.model';
import { UserRoles } from '../models/user-roles.model';
import { UserMgmtService } from '../user-mgmt-service/user-mgmt-service.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { EvaluatorsListService } from '../evaluators-list-service/evaluators-list-service.service';
import { EvaluatorModel } from '../models/evaluator-model';

export class ListItems {
  uid: string;
  userData: UserData;
  userRoles: UserRoles;
}


@Component({
  selector: 'app-user-mgmt-list',
  templateUrl: './user-mgmt-list.component.html',
  styleUrls: ['./user-mgmt-list.component.scss']
})
export class UserMgmtList implements OnInit {

  combinedList: ListItems[] = [];
  loading = true;

  userMgmts$: Observable<UserData[]>;
  userRoles$: Observable<UserRoles[]>;

  @ViewChild(MatSort) sort: MatSort;



  displayedColumns = ['userLastName', 'userEmail', 'userPhone', 'reguser', 'evaluator', 'sysadmin', 'edit', 'delete'];
  dataSource: MatTableDataSource<any>;

  constructor(
    public userMgmtService: UserMgmtService,
    public evaluatorListService: EvaluatorsListService,
    private router: Router,
    public dialog: MatDialog) { }

  ngOnInit() {

    this.userMgmts$ = this.userMgmtService.getAllUsers().map(items => {
      // console.log('Value Changes has returned in the list of users (component) !!', items);

      return items;
    });    // observable of userMgmts data

    this.userRoles$ = this.userMgmtService.getAllRoles().map(items => {
      // console.log('Value Changes has returned in the list of user roles (component) !!', items);
      return items;
    });    // observable of userRoles data

    this.userMgmts$.subscribe(items => {
      // console.log('subscribe has returned in the list of users (component) !!', items);
      // this.combinedList = []; // reset the array
      for (const it of items) {
        let NotHereAlready = true;
        for (const ex of this.combinedList) {
          if (ex.uid === it.uid) {
            NotHereAlready = false;
            ex.userData = it;
          }
        }
        if (NotHereAlready) {
          const tempEx = new ListItems();
          tempEx.uid = it.uid;
          tempEx.userData = it;
          this.combinedList.push(tempEx);
        }
      }
      // check for deletions
      if (items.length !== this.combinedList.length) {
        // Need to delete the missing record from this.combinedItems
        this.combinedList.forEach((ex, index) => {
          let ThisOneIsMissing = true;
          for (const it of items) {
            if (ex.uid === it.uid) {
              ThisOneIsMissing = false;
            }
          }
          if (ThisOneIsMissing) {
            this.combinedList.splice(index, 1);
          }
        });
      }
    });

    this.userRoles$.subscribe(items => {
      // console.log('subscribe has returned in the list of user roles (component) !!', items);
      for (const it of items) {
        let NotHereAlready = true;
        for (const ex of this.combinedList) {
          if (ex.uid === it.uid) {
            NotHereAlready = false;
            ex.userRoles = it;
          }
        }
        if (NotHereAlready) {
          const tempEx = new ListItems();
          tempEx.uid = it.uid;
          tempEx.userRoles = it;
          this.combinedList.push(tempEx);
        }
      }
      // console.log('Combined list has been built and it is: ', this.combinedList);
      this.loading = false;
      this.dataSource = new MatTableDataSource(this.combinedList);
      this.dataSource.sort = this.sort;

      // This is where I do my custome sorting !!
      this.dataSource.sortingDataAccessor = (item, property) => {

        // console.log('In the sorting Data Accessor - when does this happen ??')
        // console.log('What is the item ??: ', item);
        // console.log('What is the property ??: ', property);
        // return item[property];

        switch (property) {
          case 'userLastName': return item.userData.userLastName;
          case 'userEmail': return item.userData.userEmail;
          case 'userPhone': return item.userData.userPhone;
          case 'reguser': return item.userRoles.reguser;
          case 'evaluator': return item.userRoles.evaluator;
          case 'sysadmin': return item.userRoles.sysadmin;
          default: return item[property];
        }
      };

    });

  }

  trackByUid(index, item) {
    return item.uid;
  }

  // createNewUserMgmt() {

  //   this.router.navigate(['/admin', this.userMgmtService.createNewUser()]);
  // }

  addUserRolesObject(forThisId: string) {
    this.userMgmtService.createNewUserRoles(forThisId);
  }

  deleteTheUserMgmt(theIdToBeDeleted: string) {
    console.log('In user managment deleting user with id ' + theIdToBeDeleted);
    this.userMgmtService.deleteUserData(theIdToBeDeleted);

  }

  giveRegUserPerms(id: string) {
    console.log('Giving user id ' + id + ' Regular User Permissions !');
    this.userMgmtService.updateUserRoles(id, { 'reguser': true });
  }
  recindRegUserPerms(id: string) {
    console.log('Recinding user id ' + id + '\'s Regular User Permissions !');
    this.userMgmtService.updateUserRoles(id, { 'reguser': false });
  }

  async addEvaluatorToList(userRecord: any) {
    const newRecord = new EvaluatorModel();
    newRecord.uid = userRecord.uid;
    if (userRecord.userData.userDisplayName !== '') {
      newRecord.name = userRecord.userData.userDisplayName;
    } else {
      newRecord.name = userRecord.userData.userFirstName + ' ' + userRecord.userData.userLastName;
    }
    newRecord.email = userRecord.userData.userEmail;
    await this.evaluatorListService.addEvaluatorToList(newRecord);
  }

  async giveEvaluatorPerms(userRecord: any) {
    console.log('Giving user id ' + userRecord.uid + ' Evaluator Permissions !');
    const rtnResult = await this.userMgmtService.updateUserRoles(userRecord.uid, { 'evaluator': true });
    console.log('the return result is: ', rtnResult);

    if (rtnResult === 'success') {

      // add user to evaluators list
      await this.addEvaluatorToList(userRecord);

    } else {
      console.log('error updating permission trying to give evaluator perms');
    }
  }

  async recindEvaluatorPerms(userRecord: any) {
    console.log('Recinding user id ' + userRecord.uid + '\'s Evaluator Permissions !');
    const rtnResult = await this.userMgmtService.updateUserRoles(userRecord.uid, { 'evaluator': false });
    console.log('the return result is: ', rtnResult);

    if (rtnResult === 'success' && userRecord.userRoles.sysadmin === false) {
      // add user to evaluators list
      await this.evaluatorListService.removeEvaluatorFromList(userRecord.uid);
    } else {
      console.log('error updating permission trying to recind evaluator perms');
    }
  }

  async giveSysAdminPerms(userRecord: any) {
    console.log('Giving user id ' + userRecord.uid + ' Sys Admin Permissions !');
    const rtnResult = await this.userMgmtService.updateUserRoles(userRecord.uid, { 'sysadmin': true });

    console.log('the return result is: ', rtnResult);
    if (rtnResult === 'success') {

      // add user to evaluators list
      await this.addEvaluatorToList(userRecord);

    } else {
      console.log('error updating permission trying to give sysAdmin perms');
    }
  }
  async recindSysAdminPerms(userRecord: any) {
    console.log('Recinding user id ' + userRecord.uid + '\'s Sys Admin Permissions !');
    const rtnResult = await this.userMgmtService.updateUserRoles(userRecord.uid, { 'sysadmin': false });
    console.log('the return result is: ', rtnResult);

    if (rtnResult === 'success' && userRecord.userRoles.evaluator === false) {
      // add user to evaluators list
      await this.evaluatorListService.removeEvaluatorFromList(userRecord.uid);
    } else {
      console.log('error updating permission trying to recind sysAdmin perms');
    }
  }

}
