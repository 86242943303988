import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { UserData } from '../models/user-data.model';
import { UserRoles } from '../models/user-roles.model';

// import { BehaviorSubject } from 'rxjs/BehaviorSubject';
// import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/observable/of';
// import 'rxjs/add/operator/switchMap';
// import 'rxjs/add/operator/take';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';


@Injectable()
export class AuthService {


    // just do the role based Auth tutorial and then change out the DB for Firestore. *****
    userDocRef: AngularFirestoreDocument<UserData>;
    userData: BehaviorSubject<UserData> = new BehaviorSubject(null);
    userRolesRef: AngularFirestoreDocument<UserRoles>;
    userRoles: BehaviorSubject<UserRoles> = new BehaviorSubject(null);

    constructor(
        private afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private router: Router
    ) {

        //// Get auth data, then get firestore user document || null
        this.afAuth.authState
            .switchMap(auth => {
                if (auth) {
                    // Signed in
                    this.userRolesRef = this.afs.doc<UserRoles>(`Roles/${auth.uid}`);
                    // this is not ideal as I dont think this is the right timing, but may be OK.
                    this.userRolesRef.valueChanges().subscribe(roles => this.userRoles.next(roles));

                    this.userDocRef = this.afs.doc<UserData>(`Users/${auth.uid}`);
                    return this.userDocRef.valueChanges();
                    // return Observable.forkJoin(this.userDocRef.valueChanges(), this.userRolesRef.valueChanges());
                } else {
                    return Observable.of(null);
                    // return Observable.forkJoin(Observable.of(null), Observable.of(null));
                }
            })
            .subscribe(user => {
                console.log('the .subscribe in the AuthService constructor is being called ... this should be updating the user data and state:', user);
                this.userData.next(user);
                // .subscribe(res => {
                //     console.log('the .subscribe in the AuthFBService constructor is being called ... this should be updating the user data and state:', res[0], res[1]);
                //     this.userData.next(res[0]);
                //     this.userRoles.next(res[1]);
            });

    }

    googleLogin() {
        const provider = new firebase.auth.GoogleAuthProvider();
        return this.oAuthLogin(provider);
    }
    private oAuthLogin(provider) {
        return this.afAuth.auth.signInWithPopup(provider)
            .then((credential) => {
                console.log('do I get here 1 ??');
                console.log('the crendential is :', credential);
                this.updateUserData(credential.user);
            });
    }

    private updateUserData(authData, firstName: string = '', lastName: string = '') {
        // Sets user data to firestore on login
        console.log('in the updateUserData method');
        console.log('the authData is :', authData);
        console.log('in the updateUserData do I have a userData BehaviourSubject yet ?? ... here it is:');
        console.log('the userData is :', this.userData.getValue());

        const userRef: AngularFirestoreDocument<UserData> = this.afs.doc<UserData>(`Users/${authData.uid}`);  /// can I use the class member for this ??
        const userValueData: Observable<UserData> = userRef.valueChanges();
        let localUserData: any = {};

        // don't do anything with Roles object in here it is only set in User Mgmt section.
        userValueData.take(1)
            .subscribe(existingUser => {
                console.log('from the take(1).subscribe ... the existingUser is:');
                console.log(existingUser);

                if (!existingUser) {
                    // User does not exist so create the user
                    console.log('NEW USER DETECTED !!');
                    localUserData = new UserData();  // this builds out the shell in the Firestore db for the full user object.
                    localUserData.uid = authData.uid;
                    localUserData.userEmail = authData.email;
                    localUserData.userDisplayName = authData.displayName ? authData.displayName : '';
                    localUserData.userFirstName = firstName;
                    localUserData.userLastName = lastName;
                    localUserData.userPhotoURL = authData.photoURL ? authData.photoURL : '';
                    localUserData.dateCreated = new Date();
                    localUserData.createdBy = authData.uid;  // created by self when creating a user.
                    localUserData.existsInFirebase = true;

                    // get the provider data for this email used ... need to iterate the provider data array and make sure we have a match.
                    authData.providerData.forEach(profile => {
                        console.log('The provider data for this user in the forEach loop is: ', profile);
                        if (profile.email === authData.email) {
                            localUserData.loginProvider = profile.providerId;
                        }
                    });

                    // create the user roles object.
                    const rolesRef: AngularFirestoreDocument<UserRoles> = this.afs.doc<UserRoles>(`Roles/${authData.uid}`); // reference
                    const newUserRoles: UserRoles = new UserRoles();
                    newUserRoles.uid = authData.uid;
                    // set the new user defualt roles explicitly - Reg User by Default.
                    newUserRoles.exists = true;
                    newUserRoles.reguser = true;
                    newUserRoles.evaluator = false;
                    newUserRoles.sysadmin = false;
                    rolesRef.set({ ...newUserRoles }); // This creates the doc with the id if it is not already there. (don't use add)

                } else {
                    console.log('EXISTING USER DETECTED !!');
                    // User exists so just update data from Oauth provider.
                    localUserData.userEmail = authData.email;
                    localUserData.userDisplayName = authData.displayName ? authData.displayName : '';
                    localUserData.userPhotoURL = authData.photoURL ? authData.photoURL : '';

                }
                console.log('the localUserData object is now: ', localUserData);
                userRef.ref.set({ ...localUserData }, { merge: true });
            });


    }

    signOut() {
        if (this.isAuthenticated()) {  // check if currently signed in and nav to home page before signing out
            this.router.navigate(['/']);
        }

        this.afAuth.auth.signOut().then(() => {
            // this.router.navigate(['/']);
            console.log('The user has logged out ... ');
        });
    }

    userDetailsTest() {
        console.log('**************** Testing this auth service to see if I am getting what I need *******************');
        console.log('the User Data object is: ');
        console.log(this.userData.getValue());
        console.log('the result of isAuthenticated is: ', this.isAuthenticated());
        console.log('the current user Roles are: ', this.currentUserRoles());
        console.log('the result of hasExistsPermissions is: ', this.hasExistsPermissions());
        console.log('the result of hasRegularPermissions is: ', this.hasRegularPermissions());
        console.log('the result of hasSysAdminPermissions is: ', this.hasSysAdminPermissions());
        console.log('**************************************** END ****************************************************');
        console.log(' ');

    }

    // exists: boolean;
    // reguser?: boolean;
    // sysadmin?: boolean;

    isAuthenticated(): boolean {
        return (this.userData.getValue() != null);
    }

    currentUserRoles() {
        if (this.isAuthenticated() && this.userRoles.getValue() != null) {
            const temp = this.userRoles.getValue();
            const keys = _.keys(temp);
            return _.filter(keys, function (key) {
                return (temp[key]);
            });
        } else {
            return [];
        }
    }

    currentUserId(): string {
        if (this.isAuthenticated()) {
            return this.userData.getValue().uid;
        } else {
            return 'no user';
        }
    }

    currentUserEmail(): string {
        if (this.isAuthenticated()) {
            return this.userData.getValue().userEmail;
        } else {
            return 'no user';
        }
    }


    currentUserName(): string {
        const theUserData = this.userData.getValue();
        console.log('In Auth Service currentUserName and the user data is: ', theUserData);
        if (this.isAuthenticated()) {
            if (theUserData.userFirstName && theUserData.userFirstName !== '') {
                if (theUserData.userLastName && theUserData.userLastName !== '') {
                    return theUserData.userFirstName + ' ' + theUserData.userLastName;
                } else {
                    return theUserData.userFirstName;
                }
            } else if (theUserData.userLastName && theUserData.userLastName !== '') {
                return theUserData.userLastName;
            } else {
                return theUserData.userDisplayName;
            }
        } else {
            return 'no current user';
        }
    }

    hasExistsPermissions(): boolean {
        // return !_.isEmpty(_.intersection(['exists'], this.currentUserRoles()));
        if (this.isAuthenticated()) {
            return true;
        } else {
            return false;
        }
    }

    hasONLYExistsPermissions(): boolean {
        return (!this.hasRegularPermissions() && !this.hasEvaluatorPermissions() && !this.hasSysAdminPermissions());
    }

    hasRegularPermissions(): boolean {
        // return !_.isEmpty(_.intersection(['reguser'], this.currentUserRoles()));
        if (this.isAuthenticated() &&
            this.userRoles.getValue() != null &&
            this.userRoles.getValue().reguser &&
            this.userRoles.getValue().reguser === true
        ) {
            return true;
        } else {
            return false;
        }

    }

    hasEvaluatorPermissions(): boolean {
        if (this.isAuthenticated() &&
            this.userRoles.getValue() != null &&
            this.userRoles.getValue().evaluator &&
            this.userRoles.getValue().evaluator === true
        ) {
            // console.log('returning tru for as evaluator permissions !!!!');
            return true;
        } else {
            // console.log('returning FALSe for as evaluator permissions !!!!');

            return false;
        }

    }

    hasSysAdminPermissions(): boolean {
        // return !_.isEmpty(_.intersection(['sysadmin'], this.currentUserRoles()));
        if (this.isAuthenticated() &&
            this.userRoles.getValue() != null &&
            this.userRoles.getValue().sysadmin &&
            this.userRoles.getValue().sysadmin === true
        ) {
            return true;
        } else {
            return false;
        }
    }




    updateUserPermissions(permsToAdd: [string]) {
        console.log('DO NOT ATTEMPT THIS HERE >>> THIS IS ONLY DONE IN USER MGMT SECTION');

        // Probably need to break permission out to a second table to make sure that only SysAdmins can set permission's !!!
        // Lock that table on the back end to only allow users with sysAdmin permissions to update it ... then it will be more secure.
    }




    //// Email/Password Auth
    emailSignUpOld(email: string, password: string) {
        console.log('THIS CAPABILITY IS CURRENTLY DISABLED IN THE UI !!');
        // return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
        //     .then((user) => {
        //         // this.user = user;
        //         this.updateUserData(user);
        //     })
        //     .catch(error => console.log(error));
    }

    emailSignUp(email: string, password: string, displayName: string = '', firstName: string = '', lastName: string = '') {
        return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
            .then((userCredential) => {
                console.log('in authService call the fbAuth.auth.emailSignup, succeeded! the returned User Credential Object is:', userCredential);
                console.log('the returned User Object is:', userCredential.user);

                // this is a UserCredential now

                userCredential.user.updateProfile({
                    displayName: displayName,
                    photoURL: ''
                }).then(anything => {
                    // Update successful.
                    // console.log('in authService call the creating signup - do I get anything back here ?', anything);
                    // console.log('the user object is now: ', user);
                    const userRef: AngularFirestoreDocument<UserData> = this.afs.doc<UserData>(`Users/${userCredential.user.uid}`);

                    this.updateUserData(userCredential.user, firstName, lastName);
                    return userCredential.user;

                }).catch(error => {
                    // An error happened.
                    console.log('in authService call the user profile update has failed with: ', error);
                    throw error;
                });
            })
            .catch((error) => {
                console.log('in authService call the afAuth.auth.createUserWithEmailAndPassword has failed with: ', error);
                throw error;
            });
    }

    emailLogin(email: string, password: string) {
        return this.afAuth.auth.signInWithEmailAndPassword(email, password)
            .then((user) => {
                console.log('in authService call the fbAuth.auth.emailLogin, succeeded! the returned User Object is:', user);
                this.updateUserData(user);
                return user;
            })
            .catch((error) => {
                console.log('in authService call the bAuth.auth.emailLogin has failed with: ', error);
                throw error;
            });
    }

    // Sends email allowing user to reset password
    resetPassword(email: string) {
        const fbAuth = firebase.auth();

        return fbAuth.sendPasswordResetEmail(email)
            // let the caller respond to any errors so the know what worked.
            .then(() => {
                console.log('in authService call the fbAuthSendPasswordResetEmail email send succeeded');
            })
            .catch((error) => {
                console.log('in authService call the fbAuthSendPasswordResetEmail has failed with: ', error);
                throw error;
            });
    }

    // Try creating a user ...
    createNewUser(email: string, pass: string): Promise<string> {
        // This is clearly a HACK !!  Should do this from the back end but if it works !!!
        const config = {
            apiKey: 'AIzaSyA65k0l76W_5-vQu4YkTm1e91-ZCNpsAeo',
            authDomain: 'practice-evaluation-3.firebaseapp.com',
            databaseURL: 'https://practice-evaluation-3.firebaseio.com',
        };

        const secondaryApp = firebase.initializeApp(config, 'Secondary');

        return secondaryApp.auth().createUserWithEmailAndPassword(email, pass)
            .then(function (userCredential) {
                console.log('New User ' + userCredential.user.uid + ' created successfully!');
                const theUid = userCredential.user.uid;
                // I don't know if the next statement is necessary
                secondaryApp.auth().signOut();
                return theUid;
            })
            .catch(function (error) {
                console.log('ERROR occurred creating new user in Firebase !!! error is: ', error);
                return error;
            });
    }
}
