import { Component, OnInit, ViewChild, Input, ViewChildren, AfterViewInit, ElementRef, QueryList } from '@angular/core';
import { MatTableDataSource, MatTable, MatSort, MatPaginator, MatIcon } from '@angular/material';
import * as _ from 'lodash';
import { MarkupTagService } from '../markup-tag-service/markup-tag-service.service';
import { EvaluationModel } from '../models/evaluation-model';
import { MarkupTagModel } from '../models/markup-tag-model';
import { VideoService } from '../video-service/video-service.service';
import { GlobalUtilitiesService } from '../utils/global-utils.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'markup-tag-list',
  templateUrl: './markup-tag-list.component.html',
  styleUrls: ['./markup-tag-list.component.scss']
})
export class MarkupTagListComponent implements OnInit, AfterViewInit {
  @Input() theEvaluation: EvaluationModel;

  @Input() videoService: VideoService;

  // @ViewChildren('icontest') theListIcons: MatIcon[];
  @ViewChildren('icontest', { read: ElementRef }) theListIcons: QueryList<ElementRef>;
  @ViewChildren('acell', { read: ElementRef }) theCells: QueryList<ElementRef>;
  @ViewChildren('maindiv', { read: ElementRef }) theMainDivs: QueryList<ElementRef>;
  // @ViewChild('icontest') theIcontest: ElementRef;

  displayedColumns = ['single'];
  dataSource: MatTableDataSource<MarkupTagModel>;
  // elementData: Element[];
  // tempElementData: any;

  sortIndex: string[] = ['none', 'none', 'none'];

  @ViewChild(MatTable) dataTable: MatTable<MarkupTagModel>;
  // @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator) customPaginator: MatPaginator;


  constructor(
    public markupTagService: MarkupTagService,
    public gu: GlobalUtilitiesService,
    // public authService: AuthService,
    // private router: Router,
    // private dialog: MatDialog
  ) { }



  ngOnInit() {

    this.markupTagService.getAllVideoMarkupTagsForEvaluation(this.theEvaluation.uid).subscribe( markupTags => {
      this.dataSource = new MatTableDataSource(markupTags);
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;

      this.sortColumn(0);
    });

  }

  ngAfterViewInit() {
    console.log('in the afterViewInit of the tag list');
    console.log('The list of icons', this.theListIcons);
    console.log('the length of the icon list is: ', this.theListIcons.length);

    console.log('The list of cells', this.theCells);

    console.log('The list of main Divs', this.theMainDivs);

    console.log('What is the mat-table look like', this.dataTable);

    this.theListIcons.forEach(anIcon => {
      console.log('this Icon is: ', anIcon);
    });

    this.theListIcons.changes.subscribe(() => {
      this.theListIcons.forEach(anIcon => {
        console.log('after the subscribe this Icon is: ', anIcon);
      });

      this.theListIcons.toArray()[0].nativeElement.innerHTML = 'filter_1';
      this.theListIcons.toArray()[1].nativeElement.innerHTML = 'filter_2';
      this.theListIcons.toArray()[2].nativeElement.innerHTML = 'filter_3';
      this.theListIcons.toArray()[3].nativeElement.innerHTML = 'filter_4';
      this.theListIcons.toArray()[4].nativeElement.innerHTML = 'filter_5';
      this.theListIcons.toArray()[5].nativeElement.innerHTML = 'filter_6';
      this.theListIcons.toArray()[6].nativeElement.innerHTML = 'filter_7';

    });
  }

  sortColumn(theColNumber: number) {

    console.log('in the sortColumn routine !! for column: ', theColNumber);
    console.log('the data source data is: ', this.dataSource.data);

    // console.log('the icon list is is: ', this.theListIcons);

    // console.log('the length of the icon list is: ', this.theListIcons.length);
    // this.theListIcons.forEach(anIcon => {
    //   console.log('this Icon is: ', anIcon);
    // });

    // console.log('the second element is: ', this.theListIcons.toArray()[2]);

    if (theColNumber === 0) {
      if (this.sortIndex[0] === 'asc') {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'markStart', 'desc');
        this.sortIndex[0] = 'desc';
      } else {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'markStart', 'asc');
        this.sortIndex[0] = 'asc';
      }
      this.sortIndex[1] = 'none';
      this.sortIndex[2] = 'none';
    } else if (theColNumber === 1) {
      if (this.sortIndex[1] === 'asc') {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'markType', 'desc');
        this.sortIndex[1] = 'desc';
      } else {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'markType', 'asc');
        this.sortIndex[1] = 'asc';
      }
      this.sortIndex[0] = 'none';
      this.sortIndex[2] = 'none';
    } else if (theColNumber === 2) {
      if (this.sortIndex[2] === 'asc') {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'dateCreated', 'desc');
        this.sortIndex[2] = 'desc';
      } else {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'dateCreated', 'asc');
        this.sortIndex[2] = 'asc';
      }
      this.sortIndex[0] = 'none';
      this.sortIndex[1] = 'none';
    }
  }

  jumpToTagTimeInVideo(event: Event, theElement: MarkupTagModel) {
    event.stopPropagation();
    console.log('In the jump to time in video for markup tag: ', theElement);
    this.videoService.seekToTimeInSec(theElement.markStart);
  }

}

