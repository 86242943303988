import { Component } from '@angular/core';
import { VideoService } from '../video-service/video-service.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'video-tag-entry-form',
  templateUrl: './video-tag-entry-form.component.html',
  styleUrls: ['./video-tag-entry-form.component.scss']
})
export class VideoTagEntryFormComponent {

  constructor(public videoService: VideoService) { }

  handleVoiceNoteUrlEvent(event) {
    console.log('A voice note Url was returned, the event is: ', event);
    this.videoService.newItemEntry.voiceNoteUrl = event.theUrl;
  }
}
