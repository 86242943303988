import { Component, OnInit, Input } from '@angular/core';
import { EvaluationModel } from '../models/evaluation-model';
import { VideoService } from '../video-service/video-service.service';
import { FormControl } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { EvaluationsService } from '../evaluations-service/evaluations-service.service';
import { StorageService } from '../utils/storage-service/storage.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'evaluator-comments-report-area',
  templateUrl: './evaluator-comments-report-area.component.html',
  styleUrls: ['./evaluator-comments-report-area.component.scss']
})
export class EvaluatorCommentsReportAreaComponent implements OnInit {
  @Input() theEvaluation: EvaluationModel;

  @Input() videoService: VideoService;

  initialComments = new FormControl('');
  public editingInitial = false;

  concludingComments = new FormControl('');
  public editingFinal = false;

  public isGeneratingPDF = false;
  public generationFailedMessage = '';

  constructor(
    private authService: AuthService,
    private evalService: EvaluationsService,
    private storageService: StorageService
  ) { }

  ngOnInit() {

    this.resetCommentValues();
  }

  isEvaluator(): boolean {
    if (this.authService.hasSysAdminPermissions()) {
      return true;
    }

    if (this.authService.currentUserId() === this.theEvaluation.evaluatorAssignedUid) {
      return true;
    }

    return false;
  }

  editInitialComments() {
    this.initialComments.setValue(this.theEvaluation.evaluatorIntroComments);
    this.editingInitial = true;
  }

  cancelEditInitial() {
    this.editingInitial = false;
  }

  saveInitialComments() {
    this.editingInitial = false;

    const smallJSON: any = {};
    smallJSON.evaluatorIntroComments = this.initialComments.value;
    smallJSON.PDFNeedsRegenerated = true;

    this.evalService.updateEvaluation(this.theEvaluation.uid, smallJSON);
    // should probably wait on a return here and lock the control until it is processed.
  }

  editFinalComments() {
    this.initialComments.setValue(this.theEvaluation.evaluatorFinalcomments);
    this.editingFinal = true;
  }

  cancelEditFinal() {
    this.editingFinal = false;
  }

  saveFinalComments() {
    this.editingFinal = false;

    const smallJSON: any = {};
    smallJSON.evaluatorFinalcomments = this.concludingComments.value;
    smallJSON.PDFNeedsRegenerated = true;

    this.evalService.updateEvaluation(this.theEvaluation.uid, smallJSON);
    // should probably wait on a return here and lock the control until it is processed.
  }

  resetCommentValues() {
    this.initialComments.setValue(this.theEvaluation.evaluatorIntroComments);

    this.concludingComments.setValue(this.theEvaluation.evaluatorFinalcomments);
  }

  grapTheScreenShots() {
    this.storageService.generateFrameGrabs(this.theEvaluation.uid, this.theEvaluation.videoURL, [1, 2]);
  }

  async generateThePDFOnTheBackend() {
    this.isGeneratingPDF = true;
    this.generationFailedMessage = '';
    // Call the evaluations service for this.
    try {
      // const thePDFResult = await this.evalService.generatePDFOnServer(this.theEvaluation.uid);

      const thePDFResult = await this.storageService.generatePDFOnServer(this.theEvaluation.uid);


      console.log('Returned successfully from generating the PDF and the returned data is: ', thePDFResult);

      if (thePDFResult.result !== 'success') {
        this.generationFailedMessage = 'The generation of the PDF failed on the backend - gracefully';
      }

      this.isGeneratingPDF = false;
      // const thePDFResult = await this.evalService.generatePDFLocally(this.theEvaluation);
    } catch (error) {
      console.log('Generating the PDF failed wiht this error :', error);
      this.generationFailedMessage = 'The generation of the PDF failed on the backend - badly';
    }

    this.isGeneratingPDF = false;

    // const thePDFResult = await this.evalService.generatePDFLocally(this.theEvaluation);
  }

}
