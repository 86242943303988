import { Component,  } from '@angular/core';
import { VideoService } from '../video-service/video-service.service';
import { AuthService } from '../auth/auth.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'video-toolbar',
  templateUrl: './video-toolbar.component1.html',
  styleUrls: ['./video-toolbar.component.scss']
})
export class VideoToolbarComponent {

  constructor(public videoService: VideoService, public authService: AuthService) { }

}
