import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SendgridService {

  constructor(private http: HttpClient) { }

  async sendWelcomeEmail(userFirstName: string, userEmail: string, evalURL: string): Promise<any> {

    const tempURL = 'https://us-central1-practice-evaluation-3.cloudfunctions.net/httpWelcomeEmail';

    const httpBody: any = {};
    // httpBody.data = theLocation
    httpBody.toName = userFirstName;
    httpBody.toEmail = userEmail;
    httpBody.evalURL = evalURL;

    return new Promise((resolve, reject) => {
      console.log('In Sendgrid Service ... and the httpBody that will be sent is: ', httpBody);

      this.http
        .post(tempURL, httpBody)
        .subscribe(
          data => {
            console.log('In the SendGrid Service send WelcomeEmail after the HTTP call');
            const resp: any = data;
            console.log('In the SendGrid Service send WelcomeEmail after the HTTP call and the tempResponse is: ', resp);

            if (resp.result === 'success') {
              resolve({
                result: 'success',
                details: 'email sent successfully'
              });
            } else {
              resolve({
                result: 'failure',
                details: 'ERROR sending email'
            });
            }

          },
          error => {
            console.log('In the error portion after the call to the backend to call sendgrid and the error is: ', error);
            reject(error);
          }
        );
    });

    // return this.http.post(tempURL, httpBody).toPromise();

  }
}
