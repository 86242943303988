import { Component } from '@angular/core';
import { VideoService } from '../video-service/video-service.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'video-progress',
  templateUrl: './video-progress.component.html',
  styleUrls: ['./video-progress.component.scss']
})
export class VideoProgressComponent {

  constructor(public videoService: VideoService) { }

}
