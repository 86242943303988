
export class UserRoles {
    public uid = ''; // set by FireBase db record id...
    public exists = true;
    public reguser = false;
    public evaluator = false;
    public sysadmin = false;

    constructor(

    ) { }

}
