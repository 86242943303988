export class MarkupTagModel {

    constructor(

        public uid: string = '', // this is the uniqueId manually create this
        public evaluationId: string = '', // this is the uniqueId of the evaluation Parent

        public dateCreated: Date = new Date(),

        public markStart: number = 0.0,
        public markType: string = '',
        public markText: string = '',
        public markColor: string = '',

        public additionalText: string = '',

        public voiceNoteUrl: string = '',

        public createdByUid: string = '',

        public screenShotUrl: string = '',
        public screenShotFullName: string = ''

    ) { }


    public fromJSON(obj: any) {
        // console.log('in the from JSON of the WorkerTestscoreModel and the obj is: ', obj);
        for (const key in obj) {
            if (this.hasOwnProperty(key)) {

                this[key] = obj[key];

            }
        }
    }

}

