import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { VoucherCodeService } from '../voucher-code-service/voucher-code-service.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { GlobalUtilitiesService } from '../utils/global-utils.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'admin-voucher-codes',
  templateUrl: './admin-voucher-codes.component.html',
  styleUrls: ['./admin-voucher-codes.component.scss']
})
export class AdminVoucherCodesComponent implements OnInit {

  public loadingBase = true;
  public settingUpAVoucher = false;
  public callingBackend = false;

  public generatedCodeData: any = {};

  public baseVoucherData$: Observable<any>;
  public baseVoucherData: any = {};

  voucherForm: FormGroup;

  expirationValuesArray = [2, 7, 10, 30, 60, 90, 180, 365];

  constructor(
    public voucherService: VoucherCodeService,
    private fb: FormBuilder,
    private authService: AuthService,
    public gu: GlobalUtilitiesService
  ) { }

  ngOnInit() {

    this.baseVoucherData$ = this.voucherService.adminGetBaseData().map(data => {
      return data;
    });

    this.baseVoucherData$.subscribe(data => {
      console.log('subscribe has returned in the case voucher data !!', data);

      this.baseVoucherData = data;

      this.loadingBase = false;

    });

    this.voucherForm = this.fb.group({
      userEmail: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,63}$')]],
      numberOfUses: 1,
      expiresIn: 30
    });
  }


  setupNewVoucherCode() {
    this.resetForm();
    this.generatedCodeData = {};
    this.settingUpAVoucher = true;
  }


  async sendEmailToUserWithVoucherCodeData() {

    console.log('in the send email with voucher code to user and the generated data is: ', this.generatedCodeData);

    if (this.generatedCodeData && this.generatedCodeData.voucherCode !== '') {
      // Call the service to send the email from the backend.

      console.log('do I make it here ????? ');
      const theSendResult = await this.voucherService.emailVoucherCodeToUser(this.generatedCodeData.emailOfUser, this.generatedCodeData.voucherCode, this.generatedCodeData.emailOfUser);

      console.log('After the voucher code email has been sent, the repsonse is: ', theSendResult);
    }
  }



  saveChanges() {
    console.log('I\'m in the save changes method of setup Voucher, the form is: ', this.voucherForm.value);



    this.callingBackend = true;
    // tslint:disable-next-line:max-line-length
    this.voucherService.adminAssignVoucherCodeToEmail(this.voucherForm.get('userEmail').value, this.voucherForm.get('expiresIn').value, this.voucherForm.get('numberOfUses').value, this.authService.currentUserId(), this.authService.currentUserName())
      .then(returnedVoucherCodeData => {
        console.log('The new voucher code was created and it is: ', returnedVoucherCodeData);

        this.generatedCodeData = returnedVoucherCodeData;
        this.settingUpAVoucher = false;
        this.callingBackend = false;
        // this.router.navigate(['/admin/usermgmtlist']);
      })
      .catch(error => {
        console.log('some stupid error occurred creating the voucher code: ', error);
        this.settingUpAVoucher = false;
        this.callingBackend = false;
      });


  }

  resetForm() {
    this.voucherForm.get('userEmail').setValue('');
    this.voucherForm.get('numberOfUses').setValue(1);
    this.voucherForm.get('expiresIn').setValue(30);
  }

  cancel() {
    this.resetForm();
    this.settingUpAVoucher = false;
  }













  async loadVoucherCodes() {
    try {
      const theLoadResult = await this.voucherService.adminAddUnusedVoucherCodesToMasterTable();
      console.log('Loaded the new voucher codes and the result was: ', theLoadResult);
    } catch (error) {
      console.log('An error occurred loading the file of Voucher Codes and the error is: ', error);
    }
  }

}
