import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDisplay'
})
export class TimeDisplayPipe implements PipeTransform {

  transform(value: number, args: string[]): string {
    const hh = Math.floor(value / 3600);
    // console.log('the timeDisplayPipe args are: ', args);
    const mm = Math.floor(value / 60) % 60;
    // var ss = Math.floor(value) % 60;
    let sss;
    if (args && args[0] === '1') {
      // console.log('IN THE IF FROM ARGS');
      sss = (Math.round(value) % 60);
    } else {
      sss = (Math.floor(value * 10) % 600) / 10;
    }

    // console.log("transfoming a time of : " + value + " into: " +
    // hh + ":" + (mm < 10 ? "0" : "") + mm + ":" + (sss < 10 ? "0" : "") + sss);

    // return hh + ":" + (mm < 10 ? "0" : "") + mm + ":" + (ss < 10 ? "0" : "") + ss;
    if (args && args[0] === '1') {
      let final = '';
      if (mm === 1) {
        final = mm + ' min ';
      } else if (mm !== 0) {
        final = mm + ' mins ';
      }
      if (sss === 1) {
        final = final + sss + ' sec';
      } else {
        final = final + sss + ' secs';
      }
      // return mm + ' mins ' + sss + ' secs';
      return final;
    } else {
      // return mm + ':' + (sss < 10 ? '0' : '') + sss;
      return (hh > 0 ? hh + ':' : '') + ((hh > 0) && (mm < 10) ? '0' + mm + ':' : (mm > 0 ? mm + ':' : '')) + ((sss < 10) && (mm > 0) ? '0' : '') + sss;
    }
  }
}
