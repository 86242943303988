import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { firestore } from '@firebase/firestore';
import { MarkupTagModel } from '../models/markup-tag-model';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../utils/storage-service/storage.service';

@Injectable({
  providedIn: 'root'
})
export class MarkupTagService {

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private storageService: StorageService
    // private http: HttpClient
  ) { }


  getAllVideoMarkupTagsForEvaluation(forEvaluationId: string): Observable<MarkupTagModel[]> {

    const MarkupTagsCollection: AngularFirestoreCollection<MarkupTagModel> = this.afs.collection('MarkupTags', ref => ref.where('evaluationId', '==', forEvaluationId)); // reference

    return MarkupTagsCollection.valueChanges().pipe(map(items => {
      console.log('Value Changes has returned in the list of Markup Tags (from service) !!', items);
      return items.map(itemd => {
        itemd.dateCreated = (itemd.dateCreated as firestore.Timestamp).toDate();
        return itemd;
      });
    }));

  }

  async addVideoMarkupTag(theTag: MarkupTagModel) {
    const MarkupTagsCollection: AngularFirestoreCollection<MarkupTagModel> = this.afs.collection('MarkupTags');
    return await MarkupTagsCollection.doc(theTag.uid).set({ ...theTag });

    // const theMarkupTag: AngularFirestoreDocument<MarkupTagModel> = this.afs.doc('MarkupTags/' + theTag.uid);
    // return await theMarkupTag.set(theTag);
  }

  async addVideoMarkupTagFromJSON(theJSON: any) {
    const newUid = this.afs.createId();

    const newTag: MarkupTagModel = new MarkupTagModel();
    newTag.fromJSON(theJSON);

    newTag.createdByUid = this.authService.currentUserId();
    newTag.dateCreated = new Date();
    newTag.uid = newUid;

    const addResult = await this.addVideoMarkupTag(newTag);
    return newUid;
  }

  async addVideoMarkupTagFromJSONwithUid(theJSON: any) {
    // const newUid = this.afs.createId();

    const newTag: MarkupTagModel = new MarkupTagModel();
    newTag.fromJSON(theJSON);
    newTag.dateCreated = new Date();
    newTag.createdByUid = this.authService.currentUserId();

    const addResult = await this.addVideoMarkupTag(newTag);
    return theJSON.uid;
  }


  async deleteVideoMarkupTag(theTagUid: string) {
    const theMarkupTag: AngularFirestoreDocument<MarkupTagModel> = this.afs.doc('MarkupTags/' + theTagUid);

    const theMarkupTagDoc = await theMarkupTag.ref.get()
      .then(theDocSnap => {
        return theDocSnap.data();
      });

    if (theMarkupTagDoc.screenShotFullName && theMarkupTagDoc.screenShotFullName !== '') {
      // delete it.
      console.log('There is a file to delete associated with the tag.');
      this.storageService.deleteFileInStorage(theMarkupTagDoc.screenShotFullName);
    } // else no screenshot to delete .

    return await theMarkupTag.delete();
  }
}
