import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EvaluationsService } from '../evaluations-service/evaluations-service.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SendgridService } from '../utils/sendgrid/sendgrid-service.service';

import { StripeService, StripeCardComponent, ElementOptions, ElementsOptions } from 'ngx-stripe';
import { PaymentService } from '../stripe/payment-service/payment-service.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { VoucherCodeService } from '../voucher-code-service/voucher-code-service.service';
import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-new-evaluation-form',
  templateUrl: './new-evaluation-form.component.html',
  styleUrls: ['./new-evaluation-form.component.scss']
})
export class NewEvaluationFormComponent implements OnInit {

  // elements for ngx-stripe component ... may want to do this locally.
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: ElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: ElementsOptions = {
    locale: 'auto'
  };

  public voucherUsed = false;  // if this is false then it is using a credit card

  public newEvalForm: FormGroup;

  public ccErrorMessage = '';
  public ccTokenId = '';
  public voucherFieldHint = '';

  public validatingVoucher = false;
  public validatingCard = false;
  public usingVoucher = false;
  public chargingCard = false;
  public creatingEval = false;
  public sendingWelcome = false;

  private lastLength = 0; // This is to help me removing dashes.
  public voucherPatternValid = true;

  constructor(
    private fb: FormBuilder,
    private evalService: EvaluationsService,
    private sendgrid: SendgridService,
    private router: Router,
    public theRoute: ActivatedRoute,
    private stripeService: StripeService,
    private paymentService: PaymentService,
    private voucherService: VoucherCodeService,
    private afs: AngularFirestore
  ) { }

  ngOnInit() {

    // this.theRoute.params
    //   .map((params: Params) => params)
    //   .subscribe(params => {
    //     this.voucherUsed = (params.method === 'voucher');
    //     if (params.method !== 'voucher') {
    //       this.voucherPatternValid = true;
    //     }
    //   });
    this.newEvalForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,63}$')]],
      nccpNumber: '',
      voucherNumber: ''
    });

    if (this.theRoute.snapshot.paramMap.get('method') === 'voucher') {
      this.voucherUsed = true;
      this.voucherPatternValid = false;
      console.log('method is voucher !!');
    } else {
      console.log('method is visa !!');
    }

    if (this.theRoute.snapshot.queryParamMap.has('code')) {
      this.voucherNumber.setValue(this.theRoute.snapshot.queryParamMap.get('code'));
      this.voucherPatternValid = true; // otherwise the buttons are disabled.
    }

    this.voucherNumber.valueChanges.subscribe(
      cnumber => {
        this.formatVoucherCode(cnumber);
      }
    );

    this.email.valueChanges.subscribe(
      tempEmail => {
        this.resetErrorOnChange(tempEmail);
      }
    );
  }

  get voucherNumber() {
    return this.newEvalForm.get('voucherNumber');
  }

  get email() {
    return this.newEvalForm.get('email');
  }

  resetErrorOnChange(theString: string) {
    if (this.voucherUsed && this.ccErrorMessage !== '') {
      this.ccErrorMessage = '';
      // Need to reset this here for the case whent eh email did not match the email on the voucher and is being updated.
    }
  }

  formatVoucherCode(theString: string) {
    let newString: string;
    let tempString = theString.split('-').join('').replace(/\s+/g, '').toUpperCase();

    console.log('The Temp string is: ' + tempString + ', and lastLength is: ', this.lastLength);

    if (tempString.length > 3 && tempString.substr(0, 2) === 'CC') {

      tempString = tempString.substr(0, 13);

      if (tempString.length > 12) {
        newString = tempString.substr(0, 4) + '-' + tempString.substr(4, 4) + '-' + tempString.substr(8, 4) + '-' + tempString.substr(12, 1);
        this.lastLength = tempString.length;
        this.voucherPatternValid = true;
        this.ccErrorMessage = '';
      } else if (tempString.length === 12) {
        if (this.lastLength !== 12) {
          newString = tempString.substr(0, 4) + '-' + tempString.substr(4, 4) + '-' + tempString.substr(8, 4) + '-';
          this.lastLength = tempString.length;
        } else {
          newString = tempString.substr(0, 4) + '-' + tempString.substr(4, 4) + '-' + tempString.substr(8, 3);
          this.lastLength = 11;
        }
        this.voucherPatternValid = false;
      } else if (tempString.length > 8) {
        newString = tempString.substr(0, 4) + '-' + tempString.substr(4, 4) + '-' + tempString.substr(8, 4);
        this.lastLength = tempString.length;
      } else if (tempString.length === 8) {
        if (this.lastLength !== 8) {
          newString = tempString.substr(0, 4) + '-' + tempString.substr(4, 4) + '-';
          this.lastLength = tempString.length;
        } else {
          newString = tempString.substr(0, 4) + '-' + tempString.substr(4, 3);
          this.lastLength = 7;
        }
        this.voucherPatternValid = false;
      } else if (tempString.length > 4) {
        newString = tempString.substr(0, 4) + '-' + tempString.substr(4, 4);
        this.lastLength = tempString.length;
      } else if (tempString.length === 4) {
        if (this.lastLength !== 4) {
          newString = tempString.substr(0, 4) + '-';
          this.lastLength = tempString.length;
        } else {
          newString = tempString.substr(0, 3);
          this.lastLength = 3;
        }
        this.voucherPatternValid = false;
      }

    } else {
      newString = tempString;
      this.lastLength = tempString.length;
      if (tempString.length >= 4) {
        this.voucherPatternValid = true;
        this.ccErrorMessage = '';
      } else {
        this.voucherPatternValid = false;
      }
    }


    this.voucherNumber.setValue(newString, { emitEvent: false });

  }

  async checkVoucherValidity() {

    this.validatingVoucher = true;

    try {

      const voucherResult = await this.voucherService.checkVoucherCodeValidity(this.voucherNumber.value, this.email.value);
      console.log('The result of the Validity check on the vouchere is: ', voucherResult);

      if (voucherResult.result === 'NOT VALID') {
        this.ccErrorMessage = voucherResult.reason;
        this.voucherFieldHint = '';
      } else if (voucherResult.result === 'ERROR') {
        this.ccErrorMessage = 'A system error occurred please try again later';
        this.voucherFieldHint = '';
      } else {
        this.ccErrorMessage = '';
        this.voucherFieldHint = voucherResult.message;
      }

      this.validatingVoucher = false;

    } catch (error) {
      console.log('in the Submit Form for Voucher for new Eval try block and something failed - the error is: ', error);
      this.validatingVoucher = false;
    }

  }


  async cardValidationEvent(e) {
    console.log('The Card Element widget emitted an event ... and it was: ', e);
    // console.log(' the event type is ', e.type);

    if (e.type === 'focus') {
      // this.ccErrorMessage = '';
    } else if (e.type === 'blur') {
      // try and get the token.
      // console.log(' do I get here ', e.type);
      this.stripeService.createToken(this.card.getCard(), { name: this.newEvalForm.get('firstName').value + this.newEvalForm.get('lastName').value })
        .subscribe(tempToken => {
          console.log('In the card validation event code and the token created is:', tempToken);
          if (tempToken.error) {
            this.ccErrorMessage = tempToken.error.message;
          } else {
            this.ccErrorMessage = '';
            this.ccTokenId = tempToken.token.id;
          }
        });

    } else if (e.type === 'change') {
      this.ccTokenId = '';
      if (e.event && e.event.error) {
        this.ccErrorMessage = e.event.error.message;
      } else {
        this.ccErrorMessage = '';
      }
    }
  }

  resetFormValues() {
    console.log('in reset form values and it does nothing');
    this.newEvalForm.get('firstName').setValue('');
    this.newEvalForm.get('lastName').setValue('');
    this.newEvalForm.get('email').setValue('');
    this.newEvalForm.get('nccpNumber').setValue('');
    this.newEvalForm.get('voucherNumber').setValue('');
    // *** How do I reset the credit card form ?

    if (!this.voucherUsed) {
      this.card.getCard().clear();
    }

    this.ccErrorMessage = '';
  }

  async submitForm() {
    console.log('in the Submit Form and the values are: ', this.newEvalForm.value);

    if (!this.voucherUsed) {

      this.validatingCard = true;

      // const tokenResult = await this.stripeService
      // .createToken(this.card.getCard(), { name: this.newEvalForm.get('firstName').value + this.newEvalForm.get('lastName').value }).toPromise();

      this.stripeService
        .createToken(this.card.getCard(), { name: this.newEvalForm.get('firstName').value + ' ' + this.newEvalForm.get('lastName').value })
        .subscribe(async tokenResult => {


          console.log('in the Submit Form after the token result');

          if (tokenResult.token) {
            console.log('in the Submit Form after the token result - in If');

            // Use the token to create a charge or a customer
            // https://stripe.com/docs/charges
            this.ccTokenId = tokenResult.token.id;
            this.ccErrorMessage = '';
            console.log(tokenResult.token);
            this.validatingCard = false;

            try {

              this.chargingCard = true;
              const evalId = this.afs.createId();
              const chargeResult = await this.paymentService.callBackendToMakeActualCharge(this.ccTokenId, evalId, 'usd', 9700, this.newEvalForm.get('email').value);

              console.log('Returned from charging the card in the New Evaluation Form Component ... and the charge result is: ', chargeResult);

              this.chargingCard = false;

              this.creatingEval = true;
              // const evalUid = { 'uid': 'this-is-temp-evalUid' };
              const evalUid = await this.evalService.createNewEvaluation({
                ...this.newEvalForm.value,
                'uid': evalId,
                'creditCardOrVoucher': 'card',
                'statusKey': 3,
                'stripeTokenId': tokenResult.token.id,
                'stripeChargeTransactionId': chargeResult.chargeId,
                'stripeChargeReceiptNumber': chargeResult.receiptNumber,
                'paid': chargeResult.chargePaidBoolean,
                'datePaid': new Date(),
                'dateReceiptEmailSent': new Date(),
                'amountPaid': chargeResult.chargeAmount,
                'creditCardBrand': tokenResult.token.card.brand
              });
              console.log('After creating the evaluation the evalUid is: ', evalUid);
              this.creatingEval = false;

              this.sendingWelcome = true;
              const emailResult = await this.sendgrid.sendWelcomeEmail(this.newEvalForm.get('firstName').value, this.newEvalForm.get('email').value, 'https://pe-staging.com/evaluation/' + evalUid.uid);
              console.log('After sending the welcome email the result from the service / cloud function is: ', emailResult);
              this.sendingWelcome = false;

              this.router.navigate(['/evaluation/' + evalUid.uid]);

            } catch (error) {
              console.log('in the Submit Form for card for new Eval try block and something failed - the error is: ', error);
              this.sendingWelcome = false;
              this.creatingEval = false;
            }

          } else if (tokenResult.error) {
            console.log('in the Submit Form after the token result - in elseIf');
            // Error creating the token
            console.log(tokenResult.error.message);
            this.ccErrorMessage = tokenResult.error.message;
            this.ccTokenId = '';
            this.validatingCard = false;
            return;
          } else {
            console.log('in the Submit Form after the token result - in the else');
          }


        });

    } else { // voucher used

      const voucherIsValid = await this.subroutineToValidateVoucher();

      if (voucherIsValid) { // use the voucher.
        try {

          this.usingVoucher = true;
          const evalId = this.afs.createId();
          const voucherUseResult: any = await this.voucherService.requestToUseVoucherCode(this.voucherNumber.value, this.email.value, evalId);

          console.log('Returned from request to use the Voucher in the New Evaluation Form Component ... and the charge result is: ', voucherUseResult);

          if (voucherUseResult.result === 'VALID') {
            this.usingVoucher = false;

            this.creatingEval = true;
            // const evalUid = { 'uid': 'this-is-temp-evalUid' };
            const evalUid = await this.evalService.createNewEvaluation({
              ...this.newEvalForm.value,
              'uid': evalId,
              'creditCardOrVoucher': 'voucher',
              'statusKey': 3,
              'voucherCodeUsed': this.voucherNumber.value,
              'paid': voucherUseResult.isPaid,
              'datePaid': new Date(),
              'amountPaid': voucherUseResult.ammountPaid
            });
            console.log('After creating the evaluation with Voucher the evalUid is: ', evalUid);
            this.creatingEval = false;

            this.sendingWelcome = true;
            const emailResult = await this.sendgrid.sendWelcomeEmail(this.newEvalForm.get('firstName').value, this.newEvalForm.get('email').value, 'https://pe-staging.com/evaluation/' + evalUid.uid);
            console.log('After sending the welcome email the result from the service / cloud function is: ', emailResult);
            this.sendingWelcome = false;

            this.router.navigate(['/evaluation/' + evalUid.uid]);

          } else {
            console.log('THIS SHOULD NOT HAPPEN as voucher code is validated first ... check for an error in the code.');
          }



        } catch (error) {
          console.log('in the Submit Form for voucher for new Eval try block and something failed - the error is: ', error);
          this.sendingWelcome = false;
          this.creatingEval = false;
        }
      }


    }
  }

  async subroutineToValidateVoucher(): Promise<boolean> {

    let isValid = false;

    this.validatingVoucher = true;

    try {

      const voucherResult = await this.voucherService.checkVoucherCodeValidity(this.voucherNumber.value, this.email.value);
      console.log('The result of the Validity check on the vouchere is: ', voucherResult);

      if (voucherResult.result === 'NOT VALID') {
        this.ccErrorMessage = voucherResult.reason;
        this.voucherFieldHint = '';
      } else if (voucherResult.result === 'ERROR') {
        this.ccErrorMessage = 'A system error occurred please try again later';
        this.voucherFieldHint = '';
      } else {
        this.ccErrorMessage = '';
        this.voucherFieldHint = voucherResult.message;
        isValid = true;
      }
      this.validatingVoucher = false;

    } catch (error) {
      console.log('in the Submit Form for Voucher for new Eval try block and something failed - the error is: ', error);
      this.ccErrorMessage = 'An unknown error occurred validating the voucher code';
      this.voucherFieldHint = '';
      this.validatingVoucher = false;
    }

    return isValid;

  }


}
