import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { GlobalUtilitiesService } from '../utils/global-utils.service';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPage implements OnInit {

  userForm: FormGroup;
  loading = false;
  forgot = false;
  creating = false;

  formErrors = {
    'firstName': '',
    'lastName': '',
    'email': '',
    'loginpassword': '',
    'signuppassword': '',
    'matchpassword': ''
  };
  validationMessages = {
    'firstName': {
      'onerequired': 'A first name or last name is required.',
    },
    'lastName': {
      'onerequired': 'A first name or last name is required.',
    },
    'email': {
      'required': 'Email is required.',
      'email': 'Email must be a valid email'
    },
    'loginpassword': {
      'required': 'Password is required.',
    },
    'signuppassword': {
      'required': 'Password is required.',
      'pattern': 'Password must be include at one letter and one number.',
      'minlength': 'Password must be at least 6 characters long.',
      'maxlength': 'Password cannot be more than 60 characters long.'
    },
    'matchpassword': {
      'MatchPassword': 'Passwords must match'
    }
  };

  constructor(
    private router: Router,
    private authService: AuthService,
    private fb: FormBuilder,
    public gu: GlobalUtilitiesService
  ) { }

  ngOnInit() {
    this.authService.signOut();

    this.buildForm();
  }


  buildForm(): void {
    this.userForm = this.fb.group({
      'firstName': [''],
      'lastName': [''],
      'email': ['', [
        Validators.required,
        Validators.email
      ]
      ],
      'loginpassword': ['', [
        Validators.required
      ]
      ],
      'signuppassword': ['', [
        Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9!@#$%^&*().,<>]+)$'),
        Validators.minLength(6),
        Validators.maxLength(60)
      ]
      ],
      'matchpassword': ['', [
        Validators.required
      ]
      ]

    // }, [{validator: this.MatchPassword}, {validator: this.ValidateNames}]);
    }, {validator: Validators.compose([this.MatchPassword, this.ValidateNames])}
    );

    this.userForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // reset validation messages
  }

  ValidateNamesNW(control: AbstractControl) {
    // THIS DOES NOT WORK !!! It is INVALID when the field is empty !!  I do not know WHY.
    // console.log('IN Custom Validator');
    //     if ('' === '') {
    // //      if (this.formErrors['name'] === '') {
    //       console.log('Custom Validator passed if');
    return null;
    // } else {
    //   console.log('Custom Validator FAILED !!!!!!!');
    // return { nameNotValid: true };
    // }
  }

  ValidateNames(FG: AbstractControl) {
    // console.log('this is the special case for the name field - should appear once.');
    const firstName = FG.get('firstName').value;
    // console.log('the firstName control is: ', firstNameControl);
    const lastName = FG.get('lastName').value;
    // console.log('the lastName control is: ', lastNameControl);
    // if (data && ((data.firstName === '') && (data.lastName === '')) && (firstNameControl.dirty || lastNameControl.dirty)) {
    //   this.formErrors[field] = this.validationMessages[field]['onerequired'];
    // }
    if (firstName === '' && lastName === '') {
      FG.get('firstName').setErrors( { onerequired: true} );
      FG.get('lastName').setErrors( { onerequired: true} );
    } else {
      FG.get('firstName').setErrors(null);
      FG.get('lastName').setErrors(null);

      return null;
    }


 }


  MatchPassword(FG: AbstractControl) {
    const password = FG.get('signuppassword').value; // to get value in input tag
    const confirmPassword = FG.get('matchpassword').value; // to get value in input tag
     if (password !== confirmPassword) {
         // console.log('matching passwords is false');
         FG.get('matchpassword').setErrors( {MatchPassword: true} );
     } else {
         // console.log('matching passwords is true');
         FG.get('matchpassword').setErrors(null);
         return null;
     }
 }


  // Updates validation state on form changes.
  onValueChanged(data?: any) {
    // console.log('in the onValueChanged routine and data is: ', data);
    // console.log('in the onValueChanged routine and formErrors is: ', this.formErrors);
    if (!this.userForm) { return; }
    const form = this.userForm;
    for (const field in this.formErrors) {
      // console.log('inside the first for and field is: ', field);
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';

        const control = form.get(field);
        // console.log('the field is: ', field);
        // console.log('the control is: ', control);
        // console.log('what is object here ??, ', Object.prototype.valueOf());
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
        // if (field === 'name') {
        //   console.log('this is the special case for the name field - should appear once.');
        //   const firstNameControl = form.get('firstName');
        //   console.log('the firstName control is: ', firstNameControl);
        //   const lastNameControl = form.get('lastName');
        //   console.log('the lastName control is: ', lastNameControl);
        //   if (data && ((data.firstName === '') && (data.lastName === '')) && (firstNameControl.dirty || lastNameControl.dirty)) {
        //     this.formErrors[field] = this.validationMessages[field]['onerequired'];
        //   }
        // }
      }
    }
  }


  formSubmit() {
    this.loading = true;

    if (this.forgot) {
      this.userForm.get('loginpassword').setValue('');
      this.userForm.get('signuppassword').setValue('');
      this.userForm.get('matchpassword').setValue('');
      this.authService.resetPassword(this.userForm.value['email'])
        .then((user) => {
          console.log('reset password request email sent - give the user a success message: ', user);
          this.forgot = false;
          this.loading = false;
          this.gu.alerts.push({
            type: 'success',
            msg: 'The password reset request was successfully sent ... please check your email!',
            timeout: 5000
          });
        })
        .catch(error => {
          console.log('reset password request FAILED - give the user a success message - error: ', error);
          this.gu.alerts.push({
            type: 'danger',
            msg: 'The password reset failed, ' + error.message + '!',
            timeout: 10000
          });
          this.loading = false;
        });

    } else if (this.creating) {
      let tempDisplayName = '';
      if (this.userForm.value['firstName'] === '') {
        tempDisplayName = this.userForm.value['lastName'];
      } else if (this.userForm.value['lastName'] === '') {
        tempDisplayName = this.userForm.value['firstName'];
      } else {
        tempDisplayName = this.userForm.value['firstName'] + ' ' + this.userForm.value['lastName'];
      }
      this.authService.emailSignUp(this.userForm.value['email'], this.userForm.value['signuppassword'], tempDisplayName, this.userForm.value['firstName'], this.userForm.value['lastName'])
        .then((user) => {
          this.afterSignIn();
        })
        .catch(error => {

          this.gu.alerts.push({
            type: 'danger',
            msg: 'Creating a new user failed, ' + error.message + '!',
            timeout: 10000
          });
          this.loading = false;
        });
    } else {
      this.authService.emailLogin(this.userForm.value['email'], this.userForm.value['loginpassword'])
        .then((user) => {
          this.afterSignIn();
        })
        .catch(error => {

          this.gu.alerts.push({
            type: 'danger',
            msg: 'The login failed, ' + error.message + '!',
            timeout: 10000
          });
          this.userForm.get('loginpassword').setValue('');
          this.loading = false;
        });
    }
  }




  forgottenPassword() {
    console.log('user has forgotten password ... change up screen and set up for Password');
    this.creating = false;
    this.forgot = true;
    this.userForm.get('loginpassword').setValue('');
    this.userForm.get('signuppassword').setValue('');
    this.userForm.get('matchpassword').setValue('');
  }

  cancelForgot() {
    this.forgot = false;
    this.userForm.get('loginpassword').setValue('');
    this.userForm.get('signuppassword').setValue('');
    this.userForm.get('matchpassword').setValue('');
  }

  createUserAccountWithEmailAndPassword() {
    this.forgot = false;
    this.creating = true;
    this.userForm.get('loginpassword').setValue('');
    this.userForm.get('signuppassword').setValue('');
    this.userForm.get('matchpassword').setValue('');
  }

  cancelCreate() {
    this.creating = false;
    this.userForm.get('loginpassword').setValue('');
    this.userForm.get('signuppassword').setValue('');
    this.userForm.get('matchpassword').setValue('');
}

  signInWithGoogle(): void {
    this.authService.googleLogin()
      .then(() => {
        console.log('Sign in with Google successful !!');
        this.afterSignIn();
      });
  }

  /// Shared
  private afterSignIn(): void {
    // Do after login stuff here, such router redirects, toast messages, etc.
    this.router.navigate(['/']);
  }

}
