import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { EvaluationsService } from '../evaluations-service/evaluations-service.service';
import { Observable } from 'rxjs/Observable';
import { EvaluationModel } from '../models/evaluation-model';
import { ItemUploadService } from '../utils/file-upload/item-upload.service';
import { ItemUpload } from '../utils/file-upload/item-upload';
import { VideoService } from '../video-service/video-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { StorageService } from '../utils/storage-service/storage.service';

@Component({
  selector: 'app-evaluation-detail-page',
  templateUrl: './evaluation-detail-page.component.html',
  styleUrls: ['./evaluation-detail-page.component.scss']
})
export class EvaluationDetailPage implements OnInit {
  @Input()
  public evaluationId;

  public theEvaluationsDoc$: Observable<EvaluationModel>;
  public theEvaluationDoc: EvaluationModel;
  public panelOpenState = false;
  public showTagger = false;
  public numberOfTags = 0;

  public selectedVideoFile: File | null;
  public currentVideoUpload: ItemUpload;
  public isUploading = false;
  public showUpload = false;

  constructor(
    private afs: AngularFirestore,
    public theRoute: ActivatedRoute,
    public router: Router,
    private evalService: EvaluationsService,
    public upSvc: ItemUploadService,
    public videoService: VideoService,
    private storageService: StorageService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'visa-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cards/outline/visa.svg'));
    iconRegistry.addSvgIcon(
      'amex-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cards/outline/amex.svg'));
    iconRegistry.addSvgIcon(
      'diners-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cards/outline/diners.svg'));
    iconRegistry.addSvgIcon(
      'discover-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cards/outline/discover.svg'));
    iconRegistry.addSvgIcon(
      'mastercard-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cards/outline/mastercard.svg'));
    iconRegistry.addSvgIcon(
      'maestro-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cards/outline/maestro.svg'));
    iconRegistry.addSvgIcon(
      'jcb-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cards/outline/jcb.svg'));
    iconRegistry.addSvgIcon(
      'unionpay-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cards/outline/unionpay.svg'));
    iconRegistry.addSvgIcon(
      'coupon-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cards/outline/coupon.svg'));
    iconRegistry.addSvgIcon(
      'default-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cards/outline/default.svg'));
  }

  ngOnInit() {

    this.theRoute.params
      .map((params: Params) => params)
      .subscribe(params => this.evaluationId = params.evalId);

    console.log('Got the parameter for this Evaluations and the ID is: ' + this.evaluationId);

    this.theEvaluationsDoc$ = this.evalService.getEvaluation(this.evaluationId);
    this.theEvaluationsDoc$.subscribe(theReturnedDoc => {
      console.log('Value Changes has returned in the single evaluation doc (from Component) !!', theReturnedDoc);
      this.theEvaluationDoc = theReturnedDoc;
      if (this.theEvaluationDoc.videoURL !== '') {
        this.showUpload = false;
      } else {
        this.showUpload = true;
      }
    });
  }


  // File upload
  detectVideoFiles($event: Event) {
    this.selectedVideoFile = ($event.target as HTMLInputElement).files[0];
  }

  uploadVideoFile() {
    const file = this.selectedVideoFile;
    if (file) {
      this.isUploading = true;
      this.currentVideoUpload = new ItemUpload(file);
      this.currentVideoUpload.owningUid = this.evaluationId;
      this.currentVideoUpload.originalFileName = file.name;

      this.upSvc.pushItemUpload(this.currentVideoUpload)
        .then(updatedUpload => {
          console.log('returned from the file upload and the updated upload is: ', updatedUpload);
          console.log('the currentVideoUpload variable is is: ', this.currentVideoUpload);

          // set the Url in the evaluation data here and update it
          const tempUpdateData: any = {};
          tempUpdateData.videoURL = updatedUpload.url;
          tempUpdateData.videoFileSize = updatedUpload.size;
          tempUpdateData.originalFileName = updatedUpload.originalFileName;
          tempUpdateData.dateVideoUploaded = new Date();
          if (this.theEvaluationDoc.evaluatorAssignedUid === '') {
            tempUpdateData.statusKey = 4;
          } else {
            tempUpdateData.statusKey = 5;
          }

          this.evalService.updateEvaluation(this.evaluationId, tempUpdateData)
            .then(async () => {
              console.log('The video upload has completed, reset the currentVideoUpload variable');
              this.currentVideoUpload = null;
              this.selectedVideoFile = null;
              this.isUploading = false;
              this.showUpload = false;
              // Get the video stats and update it again :-)
              const videoMetadata: any = await this.storageService.getVideoMetaData(tempUpdateData.videoURL);
              if (videoMetadata.result === 'success') {
                const smallJSON: any = {};
                smallJSON.videoLength = videoMetadata.metadata.format.duration;
                this.evalService.updateEvaluation(this.evaluationId, smallJSON);
              }
            })
            .catch(function (error) {
              this.isUploading = false;
              console.log('there was an error updating the Evaluation witht the uploaded Video, the error is: ', error);
            });

        })
        .catch(function (error) {
          this.isUploading = false;
          console.log('there was an error uploading the video to cloud storage, the error is: ', error);
        });
    } else {
      console.error('No file found!');
    }
  }

  handleEventWithVideoData(event) {
    console.log('this is the event callback and the event is: ', event);
    this.numberOfTags = event.numberOfTags;

    if (this.theEvaluationDoc.videoLength !== event.length) {
      const smallJSON: any = {};
      smallJSON.videoLength = event.length;

      this.evalService.updateEvaluation(this.theEvaluationDoc.uid, smallJSON);
    }
  }

  reuploadToggle() {
    this.showUpload = true;
  }
}
